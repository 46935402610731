import {Drawer} from '@mui/material';
import {toast} from 'react-toastify';
import {useRecoilState, useRecoilValue} from 'recoil';
import {editAppointment} from '../Services/AppointmentService';
import {
	appointmentEditedState,
	chosenAppointmentState
} from '../State/AppointmentState';
import {authenticatedUserState} from '../State/Auth';
import {editAppointmentDrawer} from '../State/Drawer';
import {IAppointmentEditFormModel} from '../Types/appointment';
import {AppointmentForm} from './AppointmentForm';
import {useErrorMessages} from "../Hooks/useErrorMessages";

export const EditAppointmentSlider = () => {
	const [edit, setEdit] = useRecoilState(appointmentEditedState);

	const editValues = useRecoilValue(chosenAppointmentState);

	const {
		resetAllErrorMessages,
		handleErrorResponse
	} = useErrorMessages();

	const auth = useRecoilValue(authenticatedUserState);

	const [editAppointmentDrawerIsOpen, setEditAppointmentDrawer] = useRecoilState(editAppointmentDrawer);

	const onSubmit = async (values: IAppointmentEditFormModel) => {

		try {
			values.createdBy = auth?.profile.name;

			await editAppointment(values);

			toast.success('Appointment successfully edited');

			setEdit(edit + 1);

			setEditAppointmentDrawer(false);

			resetAllErrorMessages();

		} catch (e) {
			handleErrorResponse(e);
			toast.error('Appointment update failed');
		}
	}

	return (
		<>
			<Drawer
				onClose={() => setEditAppointmentDrawer(false)}
				open={editAppointmentDrawerIsOpen}
				anchor="right"
				variant="temporary"
			>
				<AppointmentForm
					initialValues={editValues}
					onSubmit={onSubmit}
					label={'Edit'}
				/>
			</Drawer>
		</>
	)

}