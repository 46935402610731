import {Region} from "../Types/appointment";
import {getRegionFromValue} from "../Services/AppointmentService";
import {NavbarAutoComplete} from "../FormFields/NavbarAutoComplete";
import React from "react";

export const RegionsDropDown = ({handleChange, defaultValue}: any) => {
    return <NavbarAutoComplete
        title="Region "
        options={
            Object
                .keys(Region)
                .map((region, i) => {
                    return {
                        label: region !== "All" ? region : "Other",
                        value: Region[region as keyof typeof Region].toString()
                    }
                })
        }
        handleChange={handleChange}
        disableClearable={true}
        defaultValue={{
            label: getRegionFromValue(defaultValue),
            value: defaultValue.toString()
        }}
    />
}