import { RouteProps, Route, Redirect } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import { isUserAuthenticatedState } from '../State/Auth';

export const AuthenticatedRoute = ({ component,...rest }: RouteProps) => {
    const isAuthenticated = useRecoilValue(isUserAuthenticatedState)

    if (isAuthenticated) {
        return <Route component={component} {...rest}/>
    }
    return <Redirect to="/login" />

}