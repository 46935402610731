import Drawer from '@mui/material/Drawer/Drawer'
import React from 'react'
import {BdmForm} from "./BdmForm";
import {useBdmSlider} from "../Hooks/useBdmSlider";

export const EditBdmSlider = () => {

   const [editBdmDrawerIsOpen, handleClose, bdmValue, onSubmit] = useBdmSlider();

   return (
       <Drawer
           onClose={handleClose}
           open={editBdmDrawerIsOpen}
           anchor="right"
           variant="temporary"
       >
           <BdmForm
               onSubmit={onSubmit}
               initialValues={bdmValue}
               label={`Edit ${bdmValue?.name}`}
           />
       </Drawer>
   )
}