import {createFilterOptions, FormControl, TextField} from '@mui/material'
import Autocomplete  from '@mui/material/Autocomplete';
import {IAutoCompleteOptionsProps, IAutoCompleteProps} from '../Types/input';
import {useStyles} from './FormStyles';
import {
	Field,
	FieldProps
} from 'formik';
import {IAppointmentFormModel} from '../Types/appointment';
import {useAutoCompleteStyles} from '../theme';
import React, {useEffect, useRef, useState} from "react";
import {AutoCompleteOption} from "./AutoCompleteOption";
import {ValidationMessage} from "../Components/ValidationMessage";

export const GenericAutoComplete = ({name, options, formProps, placeholder, RenderOptions, ExtraFormSlider, TextFieldInputProps, multiple, validationProperty, customChangeHandler}: IAutoCompleteProps<any>) => {
	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		limit: 150,
	});

	const [autoCompleteValue, setAutoCompleteValue] = useState<IAutoCompleteOptionsProps | null>(null);
	const valueRef = useRef<IAutoCompleteOptionsProps | null>(null);

	useEffect(() => {
		if (valueRef.current) {
			setAutoCompleteValue(valueRef.current)
		}
	})

	const handleOnChange = (name: string, newValue?: any) => {
		if(multiple){
			formProps.setFieldValue(name, newValue?.map((x: any) => x.value));
		} else {
			formProps.setFieldValue(name, newValue?.value);
		}
		if(customChangeHandler){
			customChangeHandler(newValue)
		}
	}
	const classes = {
		...useStyles().classes,
		...useAutoCompleteStyles().classes
	};

	return (
		<div className={classes.formControl}>
			<FormControl variant="outlined">
				<Field>
					{(fieldProps: FieldProps<IAppointmentFormModel>) => {

						let value: any;
						if(multiple){
							const contacts = fieldProps.form.getFieldProps(name).value ?? [];

							let ids: number[] = [];
							contacts.forEach((element: any) => ids.push(element.contactId ?? element));

							value = options.filter(x => ids.includes(x.value));
						} else {
							const ids = fieldProps.form.getFieldProps(name).value ?? [''];
							const nextValue = options.find(x => x.value === ids);

							if (nextValue) {
								valueRef.current = nextValue
							}
							value = autoCompleteValue;
						}

						return (
							<div>
								<Autocomplete
									filterOptions={filterOptions}
									multiple={multiple}
									value={value}
									isOptionEqualToValue={(e, value) => e.value === value.value}
									getOptionLabel={(options) => options.label}
									options={options}
									renderOption={(props, option, state) => {
										return (
											RenderOptions ?
												<RenderOptions option={option} inputProps={props} key={`item-${option.value}`}/> :
												<AutoCompleteOption option={option} inputProps={props} key={`item-${option.value}`}/>
										)
									}}
									className={classes.formField}
									onChange={(e, newValue) => {
										handleOnChange(name, newValue)
									}}
									onBlur={(e) => {
										formProps.handleBlur(e)
									}}
									renderInput={(params) =>
										<div>
											{(TextFieldInputProps === undefined &&
												<TextField
												{...params}
												name={name}
												fullWidth
												variant="outlined"
												placeholder={placeholder}
												className={classes.formField}
											/>)}
											{(TextFieldInputProps !== undefined &&
												<TextField
													{...params}
													name={name}
													fullWidth
													variant="outlined"
													InputProps={TextFieldInputProps(params)}
													placeholder={placeholder}
													className={classes.formField}
												/>)}

										</div>
									}
								/>
								{(ExtraFormSlider !== undefined && <ExtraFormSlider fieldProps={fieldProps}/>) }
							</div>
						);
					}}
				</Field>
			</FormControl>
			{validationProperty &&
			<ValidationMessage propertyName={validationProperty} props={formProps} />
			}
		</div>
    );
}