import axios from "axios"
import {
	IContactBookAppointmentFormModel,
	IContactEditFormModel,
	IContactFormModel,
	IContactModel
} from '../Types/Contact'
import { rootUrl } from "./AppointmentService"

export const getContacts = async () => axios.get<IContactModel[]>(`${rootUrl}/contacts`)

export const createContact = async (contact: IContactFormModel) => axios.post(`${rootUrl}/contact`, contact);

export const createContactBooking = async (contactBooking:IContactBookAppointmentFormModel) => axios.post(`${rootUrl}/contact/booking`,contactBooking);

export const getContactById = async (contactId:number) => await axios.get(`${rootUrl}/contact/${contactId}`);

export const editContactById = async (contact:IContactEditFormModel) => await axios.put(`${rootUrl}/contact/${contact.contactId}`,contact);