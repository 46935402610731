export interface QuestionSet {
    title: string,
    questions: Question[]
}

export interface Question {
    id: number
    text: string
    score: number
    isSelected: boolean
    disabled: boolean,
    type: FeedbackType
}

export interface FeedbackProps {
    toggleSelected: (id: number) => void
}

export interface FeedbackQuestionProps extends FeedbackProps {
    question: Question,
    disabled: boolean
}

export interface FeedbackQuestionSetProps extends FeedbackProps {
    questionSet: QuestionSet,
    meetingNotRelevant: boolean,
}

export interface MeetingNotRelevantSelectProps {
    meetingNotRelevantReason: number,
    handleMeetingNotRelevantReasonChange: (value: number) => void;
}

export interface QuestionsFeedback {
    feedbackType: FeedbackType,
    checked: boolean
}

export interface FeedbackSubmit {
    score: number,
    meetingNotRelevant: boolean,
    meetingNotRelevantReason: number,
    mrrValue?: number,
    proposalSent: boolean,
    questionsFeedback: QuestionsFeedback[],
    feedbackComment?: string
}

export const MeetingNotRelevantReasons = {
    "1": "Low value",
    "2": "No direct bookings",
    "3": "User of platform in do not contact status",
    "4": "B2B Business"
}

export const MeetingNotRelevantReason = (meetingNotRelevantReason?: string) =>{
    return MeetingNotRelevantReasons[meetingNotRelevantReason as keyof typeof MeetingNotRelevantReasons];
}

export enum FeedbackType {
    BookableOnline,
    AboveMinimumValue,
    BookedWithCorrectDecisionMaker,
    UnderstoodTheService,
    AllFieldsCompleted,
    StatusOfLeadUpdated,
    OpportunityCreated,
    CleanNotes
}