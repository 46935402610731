import {Box, Checkbox, FormControlLabel, Grid} from "@mui/material";
import {FeedbackQuestionProps} from "../../Types/AppointmentFeedback";

export const FeedbackQuestion = (props: FeedbackQuestionProps) => {
    const {question, toggleSelected, disabled} = props

    const handleChange = () => toggleSelected(question.id)

    return (
        <Box paddingY={1} width="100%">
            <Grid container>
                <Grid item container>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={question.isSelected}
                                onChange={handleChange}
                                color="primary"
                                disabled={disabled}
                            />
                        }
                        label={question.text}
                        labelPlacement="end"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}