import {Drawer} from '@mui/material';
import {conferenceInitialValues} from '../FormikProps/InitialValues';
import {IAutoCompleteProps} from '../Types/appointment';
import {ConferenceForm} from "./ConferenceForm";
import {useCreateConferenceSlider} from "../Hooks/useCreateConferenceSlider";

export const CreateConferenceSlider = () => {

    const {
        handleClose,
        conferenceDrawerIsOpen,
        onSubmit
    } = useCreateConferenceSlider();

    return (
        <>
            <Drawer
                onClose={handleClose}
                open={conferenceDrawerIsOpen}
                anchor="right"
                variant="temporary"
            >
                <ConferenceForm
                    onSubmit={onSubmit}
                    initialValues={conferenceInitialValues}
                    label={'Create Conference'}
                />
            </Drawer>
        </>
    )
}