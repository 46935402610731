import {
    Box,
    Button,
    Collapse,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import FeedbackIcon from '@mui/icons-material/Feedback'
import React, {useEffect, useState} from 'react'
import {AppointmentStatus, ValidationStatus} from '../Types/appointment'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {editAppointmentDrawer, rescheduleDrawer} from '../State/Drawer'
import {
	appointmentState,
	deleteAppointmentState,
	editAppointmentState,
	reloadAppointmentState
} from '../State/AppointmentState'
import {IRowProps} from '../Types/input'
import {deleteDialog} from '../State/Dialog'
import {editAppointmentStatus, editDuplicateMeetingStatus, editValidationStatus} from '../Services/AppointmentService'
import {toast} from 'react-toastify'
import useTimezoneDate from '../Hooks/useTimezoneDate'
import {isAdminState} from '../State/Auth'
import {useHistory} from 'react-router'
import {useHeaderPermissions, useRowPermissions} from '../Hooks/rows'
import {MeetingNotRelevantReason, MeetingNotRelevantReasons} from "../Types/AppointmentFeedback";
import Switch from "@mui/material/Switch";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const useStyles = makeStyles()({
	tableRow: {
		height: '25%'
	},
	tableHeader: {},
	container: {
		minHeight: 1400,
	},
	table: {
		paddingBottom: 15
	},
	pagination: {},
	root: {
		'& > *': {
			// borderBottom: 'unset',
		},
	},
	expandedRow: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	row: {
		borderStyle: 'hidden',
	},
	cell: {}
});

const ValidationActions = (props: IRowProps) => {
	const { appointment: { appointmentId, meetingNotRelevant, meetingNotRelevantReason } } = props
	const [inProgress, setInProgress] = useState<boolean>(false)
	const reload = useSetRecoilState(reloadAppointmentState)
	const [duplicateMeetingState, setDuplicateMeetingState] = useState<boolean>(props.appointment.duplicateMeeting);

	const validateColumns = meetingNotRelevant ? 3 : 6;

	const validate = (status: ValidationStatus) => async () => {
		try {
			setInProgress(true)
			await editValidationStatus(appointmentId, status)
			reload(curr => curr + 1)
			toast.success('Validation status updated');
		} catch (e) {
			console.error(e)
			toast.error('Unable to update validation status');
		} finally {
			setInProgress(false)
		}
	}

	const handleDuplicateMeetingState = async () => {
		try{
			setDuplicateMeetingState(!duplicateMeetingState);
			setInProgress(true)
			await editDuplicateMeetingStatus(appointmentId, !duplicateMeetingState)
			reload(curr => curr + 1)
			toast.success('Duplicate meeting status updated');
		} catch (e) {
			console.error(e)
			toast.error('Unable to update meeting status');
		} finally {
			setInProgress(false)
		}
	}

	return (
		<Grid container>
			<Grid item xs={validateColumns}>
				<Typography variant="subtitle1" gutterBottom component="div">
					<strong>Validate</strong>
				</Typography>
			</Grid>

			<Grid item xs={validateColumns}>
				<Typography variant="subtitle1" gutterBottom component="div">
					<strong>Set meeting as duplicate/follow up</strong>
				</Typography>
			</Grid>
			
			{(meetingNotRelevant &&
				<Grid item xs={6}>
					<Typography variant="subtitle1" gutterBottom component="div">
						<strong>Meeting Flagged As Not Relevant</strong>
					</Typography>
				</Grid>
			)}

			<Grid item container xs={validateColumns}>
				<Button onClick={validate(ValidationStatus.Valid)} disabled={inProgress}>Valid</Button>
				<Button onClick={validate(ValidationStatus.Invalid)} disabled={inProgress}>Invalid</Button>
				<Button onClick={validate(ValidationStatus.Unvalidated)} disabled={inProgress}>Unvalidated</Button>
			</Grid>

			<Grid item container xs={validateColumns}>
				<Switch
					checked={duplicateMeetingState}
					color={'primary'}
					onChange={handleDuplicateMeetingState}
					name={'duplicateMeeting'}
					value={duplicateMeetingState}
					inputProps={{'aria-label': 'secondary checkbox'}}
				/>
			</Grid>

			{((meetingNotRelevant && meetingNotRelevantReason !== null) &&
				<Grid item xs={6}>
					<strong>{MeetingNotRelevantReason(meetingNotRelevantReason?.toString())}</strong>
				</Grid>
			)}
		</Grid>
	)
}

const ExpandedRow = (props: IRowProps) => {
	const { appointment } = props
	const { classes } = useStyles()
	const appointments = useRecoilValue(appointmentState);
	const isAdmin = useRecoilValue(isAdminState)

	return (
		<Box margin={2}>
			<Typography variant="h6" gutterBottom component="div">
				Appointment Details
			</Typography>
			<Typography variant="subtitle1" gutterBottom component="div">
				<strong>Contacts</strong>
			</Typography>
			<Box pb={3}>
				<Table className={classes.table} size="small" aria-label="purchases">
					<TableHead>
						<TableRow className={classes.row}>
							<TableCell width="10%">
								<strong>Name</strong>
							</TableCell>
							<TableCell width="10%">
								<strong>Email</strong>
							</TableCell>
							<TableCell width="10%">
								<strong>Phone number</strong>
							</TableCell>
							<TableCell width="10%">
								<strong>Job title</strong>
							</TableCell>
							<TableCell>
							</TableCell>
							<TableCell>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{appointments && appointment.contacts.map(x => {
							return (
								<TableRow className={classes.row}>
									<TableCell>
										{x.name}
									</TableCell>
									<TableCell>
										{x.email}
									</TableCell>
									<TableCell>
										{x.phone}
									</TableCell>
									<TableCell>
										{x.jobTitle}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Box>
			<Typography variant="subtitle1" gutterBottom component="div">
				<strong>BDMs</strong>
			</Typography>
			<Box pb={3}>
				<Table className={classes.table} size="small" aria-label="purchases">
					<TableHead>
						<TableRow className={classes.row}>
							<TableCell width="10%">
								<strong>Name</strong>
							</TableCell>
							<TableCell width="10%">
								<strong>Email</strong>
							</TableCell>
							<TableCell width="10%">
								<strong>Region</strong>
							</TableCell>
							<TableCell width="10%">
								<strong>Zoom Link</strong>
							</TableCell>
							<TableCell>
							</TableCell>
							<TableCell>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{appointments && appointment.users.map(x => {
							return (
								<TableRow className={classes.row}>
									<TableCell>
										{x.name}
									</TableCell>
									<TableCell>
										{x.email}
									</TableCell>
									<TableCell>
										{x.region}
									</TableCell>
									<TableCell>
										{x.zoomLink}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Box>
			<Typography variant="subtitle1" gutterBottom component="div">
				<strong>Company</strong>
			</Typography>
			<Box pb={3}>
				<Table className={classes.table} size="small" aria-label="purchases">
					<TableHead>
						<TableRow className={classes.row}>
							<TableCell width="10%">
								<strong>Name</strong>
							</TableCell>
							<TableCell width="10%">
								<strong>Products</strong>
							</TableCell>
							<TableCell width="10%">
								<strong>Region</strong>
							</TableCell>
							<TableCell width="10%">
								<strong>Sector</strong>
							</TableCell>
							<TableCell width="10%">
								<strong>Website</strong>
							</TableCell>
							<TableCell>

							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow className={classes.row}>
							<TableCell>
								{appointment.customer && appointment.customer.name}
							</TableCell>
							<TableCell>
								{appointment.customer && appointment.customer.products.join(', ')}
							</TableCell>
							<TableCell>
								{appointment.customer && appointment.customer.region}
							</TableCell>
							<TableCell>
								{appointment.customer && appointment.customer.sector}
							</TableCell>
							<TableCell>
								{appointment.customer && appointment.customer.website}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Box>
			{
				isAdmin && <ValidationActions appointment={appointment}/>
			}
		</Box>
	)
}

export const Rows = ({appointment}: IRowProps) => {

	const { classes, cx } = useStyles();

	const [status, setStatus] = useState<AppointmentStatus | null>(null);
	const [openTableCell, setOpenTableCell] = useState(false);

	const [dialog, setDeleteDialog] = useRecoilState(deleteDialog);
	const [editAppointmentDrawerIsOpen, setEditAppointmentDrawer] = useRecoilState(editAppointmentDrawer);
	const setDeleteToDeleteId = useSetRecoilState(deleteAppointmentState);
	const setEditAppointmentState = useSetRecoilState(editAppointmentState);
	const reload = useSetRecoilState(reloadAppointmentState)
	const setRescheduleDrawer = useSetRecoilState(rescheduleDrawer);

	const history = useHistory()
	const { canEditStatus, canEditScore } = useRowPermissions(appointment)
	const { canViewValidation, canViewScore } = useHeaderPermissions()


	useEffect(() => {
		setStatus(appointment.status);
	}, [appointment]);

	const handleStatusChange = async (e: any) => {
		setStatus(e.target.value);

		try {
			const { data } = await editAppointmentStatus(e.target.value, appointment.appointmentId);

			if (data.status === AppointmentStatus.Complete) {
				history.push(`/appointment/${appointment.appointmentId}/feedback`)
			}

			toast.success('Appointment status updated');
			reload(curr => curr + 1)

		} catch (e) {
			toast.error('Unable to update status');
		}
	}

	const handleFeedbackClick = () => history.push(`/appointment/${appointment.appointmentId}/feedback`)

	const toggleTableCell = () => setOpenTableCell(!openTableCell)

	const rootClasses = cx({
		[classes.expandedRow]: openTableCell
	})

	const handleEditDrawer = () => {
		setEditAppointmentDrawer(!editAppointmentDrawerIsOpen);
		setEditAppointmentState(appointment);
	}

	const handleDelete = () => {
		setDeleteDialog(!dialog);
		setDeleteToDeleteId(appointment.appointmentId);
	}

	const handleRescheduleDrawer = () => {
		setRescheduleDrawer(curr => !curr);
		setEditAppointmentState(appointment);
	}

	const appointmentDate = useTimezoneDate(appointment.appointmentDateTime);

	const createdDate =  useTimezoneDate(appointment.createdDate);

	return <>
        <TableRow className={rootClasses}>
            <TableCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={toggleTableCell}>
                    {openTableCell ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </TableCell>
            <TableCell>
                {appointmentDate}
            </TableCell>
            <TableCell>
                {appointment.customer.name}
            </TableCell>
            <TableCell>
                {appointment.users.map(x => x.name).join(', ')}
            </TableCell>
            <TableCell>
                {createdDate}
            </TableCell>
            <TableCell>
                {appointment.createdBy}
            </TableCell>
            <TableCell>
                {appointment.customer.region}
            </TableCell>
            <TableCell>
                {appointment.duration} Minutes
            </TableCell>
            <TableCell>
                <Select
                    variant="standard"
                    onChange={handleStatusChange}
                    value={status ?? ''}
                    disabled={!canEditStatus}>
                    <MenuItem value={AppointmentStatus.Pending}>
                        Pending
                    </MenuItem>
                    <MenuItem value={AppointmentStatus.Cancelled}>
                        Cancelled
                    </MenuItem>
                    <MenuItem value={AppointmentStatus.Complete}>
                        Complete
                    </MenuItem>
                </Select>
            </TableCell>
            {
                canViewValidation && (
                    <TableCell align="center">
                        {
                            appointment.validationStatus === ValidationStatus.Valid && <ThumbUpIcon color="primary"/>
                        }
                        {
                            appointment.validationStatus === ValidationStatus.Invalid && <ThumbDownIcon color="error"/>
                        }
                    </TableCell>
                )
            }
            {
                canViewScore && (
                    <TableCell align="center">
                        {
                            (appointment.score === 0 && !appointment.meetingNotRelevant && canEditScore) && (
                                <IconButton onClick={handleFeedbackClick} title="Feedback required" size="large">
                                    <FeedbackIcon color="primary"/>
                                </IconButton>
                            )
                        }
                        {
                            (appointment.score > 0 || appointment.meetingNotRelevant) && appointment.score
                        }
                    </TableCell>
                )
            }
            {
                canViewScore && (
                    <TableCell align="center">
                        ${appointment.mrrValue?.toLocaleString('en-US')}
                    </TableCell>
                )
            }
            <TableCell>
                <IconButton onClick={handleEditDrawer} color="primary" size="large">
                    <EditIcon/>
                </IconButton>

							{
								appointment.status === AppointmentStatus.Pending &&
								<IconButton onClick={handleRescheduleDrawer} color="primary" size="large">
									<AccessTimeFilledIcon/>
								</IconButton>
							}

            </TableCell>
            <TableCell>
                <IconButton onClick={handleDelete} color="primary" size="large">
                    <DeleteIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={12}>
                <Collapse in={openTableCell} timeout="auto" unmountOnExit>
                    <ExpandedRow appointment={appointment}/>
                </Collapse>
            </TableCell>
        </TableRow>
    </>;
}
