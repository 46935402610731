import {AppointmentMethod, Region} from './Types/appointment';

export const productOptions = [
	{label: 'Refund Protect', value: 2},
	{label: 'Protect Financial', value: 3},
	{label: 'Event Protect', value: 4},
	{label: 'Financial Services', value: 5},
];

export const sectorOptions = [
	{label: 'Transport', value: 0},
	{label: 'Hospitality', value: 1},
	{label: 'Ticketing', value: 2},
	{label: 'Sport', value: 3},
	{label: 'Travel and Tours', value: 4},
	{label: 'Mass Participation', value: 5},
	{label: 'Protect Financial', value: 6},
	{label: 'Broker / Introducer', value: 7},
	{label: 'Financial Services', value: 8}
];

export const regionOptions = [
	{label: 'Central America', value: Region["Central America"]},
	{label: 'China', value: Region.China},
	{label: 'Europe', value: Region.Europe},
	{label: 'India', value: Region.India},
	{label: 'Middle East', value: Region["Middle East"]},
	{label: 'Africa', value: Region.Africa},
	{label: 'North America', value: Region["North America"]},
	{label: 'Oceania', value: Region.Oceania},
	{label: 'South America', value: Region["South America"]},
	{label: 'Brazil', value: Region.Brazil},
	{label: 'South Asia', value: Region["South Asia"]},
	{label: 'North Asia', value: Region["North Asia"]},
];


export const methodOptions = [
	{label: 'Phone', value: AppointmentMethod.phone},
	{label: 'Zoom', value: AppointmentMethod.zoom},
	{label: 'Conference', value: AppointmentMethod.conference},
	{label: 'Face To Face', value: AppointmentMethod.faceToFace}
];