import {atom, selector} from 'recoil';
import {AppointmentSlots, IUserModel, UserPermissions} from '../Types/User';
import moment, {Moment} from 'moment';
import {availableTimeSlots} from '../Services/AppointmentService';
import {AxiosResponse} from 'axios';
import {Region} from "../Types/appointment";
import {CustomerProduct} from "../Types/Customer";

export const userState = atom<IUserModel[]>({
	key: 'userState',
	default: []
})

export const loggedInUserState = atom<IUserModel>({
	key: 'loggedInUserState',
	default: {
		userId: 0,
		email: '',
		name: '',
		zoomLink: '',
		region: 0,
		permissions: 0,
		timeZone: ''
	}
})

export const appointmentsQueryStartDateState = atom<Moment>({
	key: 'appointmentsQueryStateDateState',
	default: moment()
})

export const appointmentsQueryEndDateState = atom<Moment>({
	key: 'appointmentsQueryEndDateState',
	default: moment().add(3, 'days')
})

export const appointmentsQueryParamState = atom<number>({
	key: 'appointmentsQueryParamState',
	default: CustomerProduct.refundProtect
})


export const appointmentsQueryManagerState = atom<string>({
	key: 'appointmentsQueryManagerState',
	default: ''
})

export const appointmentQueryRegion = atom<number>({
	key: 'appointmentQueryRegionState',
	default: Region.All
})

export const appointmentQuerySector = atom<number>({
	key: 'appointmentQuerySectorState',
	default: 0
})

export const appointmentSlotsState = selector<AxiosResponse<AppointmentSlots[]>>({
	key: 'appointmentSlotsState',
	get: async ({get}) => {
		const start = get(appointmentsQueryStartDateState);
		const end = get(appointmentsQueryEndDateState);
		const param = get(appointmentsQueryParamState);
		const manager = get(appointmentsQueryManagerState)
		const region = get(appointmentQueryRegion);
		const sector = get(appointmentQuerySector);
		return await availableTimeSlots(start, end, param, manager, region, sector);
	}
})

export const userTimeZoneState = selector({
	key: 'UserTimeZoneState',
	get: ({get}) => {
		const user = get(loggedInUserState);
		return user.timeZone
	}
})

const bdmPermissions = [UserPermissions.BDM, UserPermissions.Partnerships]

export const bdmOptions = selector({
	key: 'bdmOptions',
	get: ({get}) => {
		const users = get(userState);
		const result = users
			.filter((x: IUserModel) => bdmPermissions.includes(x.permissions))
			.map((x: IUserModel) => {
				return {label: x.name, value: x.userId, email: x.email, id: x.userId};
			})
		return result
	},
	set: ({get}) =>{
		const users = get(userState);
		const result = users
			.filter((x: IUserModel) => bdmPermissions.includes(x.permissions))
			.map((x: IUserModel) => {
				return {label: x.name, value: x.userId, email: x.email, id: x.userId};
			})
		return result
	}
})
