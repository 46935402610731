import React, {useEffect, useState} from 'react';
import {CSVLink} from "react-csv";
import useAppointmentsData from '../Hooks/useAppointmentData';
import {AppointmentStatus} from "../Types/appointment";
import {FeedbackType, QuestionsFeedback} from "../Types/AppointmentFeedback";
import {EnumToDisplayString} from "../Helpers/Helpers";

const CSVFileName = () => "PG-Appointment Tracker " + new Date().toDateString() + ".csv";

const getQuestionsHeaders = () => {
    return Object.keys(FeedbackType)
        .filter((v) => isNaN(Number(v)))
        .map((feedbackType: string) => {
            return {
                label: EnumToDisplayString(feedbackType),
                key: feedbackType
            }
    })
}

const headers = [
    {label: 'Appointment Date/Time', key: 'appointmentDateTime'},
    {label: 'Company', key: 'company'},
    {label: 'BDM\'S', key: 'bdms'},
    {label: 'Booked Date/Time', key: 'createdDate'},
    {label: 'Booked By', key: 'createdBy'},
    {label: 'Region', key: 'region'},
    {label: 'Duration', key: 'duration'},
    {label: 'Status', key: 'status'},
    {label: 'Score', key: 'score'},
    ...getQuestionsHeaders()
]

const DownloadCsvFile = () => {
    const {appointments} = useAppointmentsData();

    const [mappedData, setMappedData] = useState<any[]>([])

    useEffect(() => {
        const next = appointments.map(x => {
            const {
                appointmentDateTime,
                users,
                customer,
                createdDate,
                createdBy,
                duration,
                status,
                score,
                questionsFeedback
            } = x

            const scoreValue = status === AppointmentStatus.Complete ? score : 'N/A';

            const appointmentInfo = {
                appointmentDateTime,
                createdDate,
                company: customer.name,
                bdms: users.reduce((curr: any, next: any) => `${curr} - ${next.name}`, ''),
                createdBy,
                region: customer.region,
                duration,
                status: AppointmentStatus[status],
                score: scoreValue
            }

            const appointmentRow = questionsFeedback.reduce((acc: any, curr: QuestionsFeedback) => {
                acc[FeedbackType[curr.feedbackType]] = curr.checked ? "Yes" : "No";
                return acc;
            }, appointmentInfo)

            return appointmentRow;
        })
        setMappedData(next)
    }, [appointments])

        return (
            <div className="App">
                <CSVLink
                    filename={CSVFileName()}
                    data={mappedData}
                    headers={headers}
                    className="ml-2 btn btn-success btn-sm">
                    Download CSV
                </CSVLink>
            </div>
        );

};

export {DownloadCsvFile};
