import {CustomerProduct} from "../Types/Customer";
import {EnumToDisplayString} from "../Helpers/Helpers";
import {NavbarAutoComplete} from "../FormFields/NavbarAutoComplete";
import React from "react";

export const ProductsDropDown = ({handleChange, defaultValue}: any) => {
    return <NavbarAutoComplete
        title="Product"
        options={[
            {
                label: "All",
                value: CustomerProduct.all.toString()
            },
            {
                label: "Refund Protect",
                value: CustomerProduct.refundProtect.toString()
            },
            {
                label: "Protect Financial",
                value: CustomerProduct.protectFinancial.toString()
            },
            {
                label: "Event Protect",
                value: CustomerProduct.eventProtect.toString()
            }
        ]}
        handleChange={handleChange}
        disableClearable={true}
        defaultValue={{
            label: EnumToDisplayString(CustomerProduct[defaultValue]),
            value: defaultValue.toString()
        }}
    />
}