import {Routes} from './Routes/Routes'
import {
	useRecoilValue,
	useSetRecoilState
} from 'recoil';
import './css/App.css'
import {useEffect} from 'react';
import {checkTimezone} from './Services/UserService';
import {loggedInUserState} from './State/User';
import {authenticatedUserState} from './State/Auth';
import theme from './theme';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {CssBaseline} from '@mui/material';

const App = () => {

	const authUser = useRecoilValue(authenticatedUserState);

	const setLoggedInUser = useSetRecoilState(loggedInUserState)

	useEffect(() => {
		if (authUser && authUser.profile) {
			let userName = authUser?.profile.name;
			let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
			let values = {userName, timeZone}

			checkTimezone(values).then(response => {
				if (response.status === 200) {
					const {data} = response;
					setLoggedInUser(data);
				}
			});

		}
	}, [authUser])

	return (
			<ThemeProvider theme={theme}>
					<CssBaseline/>
					<Routes/>
			</ThemeProvider>
    );
}

export default App;
