import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        marginBottom: 20,
    },
    textField: {
        position: 'absolute',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    errors: {
        color: "red",
        display: 'table'
    },
    icon : {
        cursor: 'pointer'
    },
    switchField : {
        display: 'block',
        "& label": {
            width: "240px"
        }
    }
}));