import {useRecoilState} from "recoil";
import {followUpReasonDrawer} from "../State/Drawer";
import {useErrorMessages} from "./useErrorMessages";
import {toast} from "react-toastify";
import {followUpReasonsState} from "../State/FollowUpReason";
import {IFollowUpReasonModel} from "../Types/FollowUpReason";
import {createFollowUpReason} from "../Services/FollowUpReasonService";

export const useCreateFollowUpReasonSlider = () => {
    const [followUpReasonDrawerIsOpen, setFollowUpReasonDrawer] = useRecoilState(followUpReasonDrawer);

    const {
        resetAllErrorMessages,
        handleErrorResponse
    } = useErrorMessages();

    const [followUpReasons, setFollowUpReasons] = useRecoilState(followUpReasonsState);

    const onSubmit = async (values: IFollowUpReasonModel) => {
        try {

            const followUpReason = await createFollowUpReason(values);

            toast.success('Follow up reason successfully created');

            setFollowUpReasons([...followUpReasons, followUpReason.data]);

            setFollowUpReasonDrawer(false);

            resetAllErrorMessages();

        } catch (e) {
            handleErrorResponse(e);
            toast.error('Failed to create follow up reason');
        }
    };

    const handleClose = () => {
        setFollowUpReasonDrawer(false);

        resetAllErrorMessages();
    }

    return {
        handleClose,
        followUpReasonDrawerIsOpen,
        onSubmit
    }
}