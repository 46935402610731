import { useRecoilValue } from 'recoil'
import { userTimeZoneState } from '../State/User'

export default function useTimezoneDate(date: any) {
    const timeZone = useRecoilValue(userTimeZoneState);

    if (!timeZone) {
        return null
    }

    let parsedDate = date

    if (typeof(parsedDate) === 'string') {
        parsedDate = new Date(date)
    }

    return parsedDate.toLocaleString('en-US', {timeZone})
}