import axios, {AxiosResponse} from 'axios'
import {
    IAppointmentEditFormModel,
    IAppointmentFormModel,
    IBdmAppointment,
    IFollowUpMeetingCheckModel,
    IRescheduleModel,
    Region,
    ValidationStatus
} from '../Types/appointment'
import {Moment} from 'moment'
import {AppointmentSlots} from '../Types/User';
import {IPaging} from '../Types/input';
import {FeedbackSubmit} from "../Types/AppointmentFeedback";

export const rootUrl = `${process.env.REACT_APP_API_HOST}/api`
export const integrationApiUrl = `${process.env.REACT_APP_INTEGRATION_API_HOST}/api`
export const integrationApiCode = process.env.REACT_APP_INTEGRATION_API_CODE

export const createAppointment = async (appointment: IAppointmentFormModel) => {
    return await axios.post(`${rootUrl}/appointment`, appointment)
}

export const getAppointments = async (pagination: IPaging, loggedInUser: string) => {
    return await axios.get(`${rootUrl}/appointments`, {
        params: {
            ...pagination
        }
    });
}

export const editAppointment = async (appointment: IAppointmentEditFormModel) => {
    return await axios.post(`${rootUrl}/appointment/${appointment.appointmentId}`, appointment)
}

export const deleteAppointment = async (appointmentId: number) => {
    return await axios.delete(`${rootUrl}/appointment/${appointmentId}`)
}

export const editAppointmentStatus = async (appointmentStatus: number, appointmentId: number) => {
    return await axios.put(`${rootUrl}/appointment/${appointmentId}/status`, appointmentStatus)
}

export const availableTimeSlots = async (start: Moment, end: Moment, param: number, manager: string, region: number, sector: number): Promise<AxiosResponse<AppointmentSlots[]>> => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDate = start.format();
    const endDate = end.format();

    return await axios.get<AppointmentSlots[]>(`${rootUrl}/appointmentslots`, {
        params: {
            startDate,
            endDate,
            timezone,
            param,
            manager,
            region,
            sector
        }
    })
}

export const editValidationStatus = async (appointmentId: number, validationStatus: ValidationStatus) =>
    await axios.patch(`${rootUrl}/appointment/${appointmentId}/validation-status`, validationStatus)

export const submitFeedback = async (appointmentId: number, feedback: FeedbackSubmit) =>
    await axios.patch(`${rootUrl}/appointment/${appointmentId}/feedback`, feedback)

export function getRegionFromValue(value: number){
    return Object.keys(Region).find(region => Region[region as keyof typeof Region] == value)
}

export const getIntegrationMember = async (memberId: number) => {
    return await axios.get(`${integrationApiUrl}/member/${memberId}?code=${integrationApiCode}`)
}

export const getUserCalendarAppointments = async (id: number, start: Moment, end: Moment) => {
    const startDate = start.format();
    const endDate = end.format();
    return await axios.get<IBdmAppointment[]>(`${rootUrl}/user/appointments`, {
        params: {
            id,
            startDate,
            endDate
        }
    })
}

export const editDuplicateMeetingStatus = async (appointmentId: number, status: boolean) =>
    await axios.patch(`${rootUrl}/appointment/${appointmentId}/duplicate-status`, status)

export const rescheduleAppointment = async (rescheduleModel: IRescheduleModel) =>
    await axios.patch(`${rootUrl}/appointment/${rescheduleModel.appointmentId}/reschedule`, rescheduleModel)

export const checkIfFollowUpMeeting = async (followUpCheckModel: IFollowUpMeetingCheckModel) => {
    return await axios.post(`${rootUrl}/appointment/check-follow-up`, followUpCheckModel)
}