import {useRecoilValue} from 'recoil';
import React from 'react';
import {AutoCompleteOption} from './AutoCompleteOption';
import {canEditBdmInfo} from "../Services/BdmService";
import {authenticatedUserState, isAdminState} from "../State/Auth";
import {useEditBdmSlider} from "../Hooks/useEditBdmSlider";
import {IAutoCompleteOptionElementProps} from "../Types/input";

export const BDMAutoCompleteOption = ({option, inputProps}: IAutoCompleteOptionElementProps) => {
    const isAdmin = useRecoilValue(isAdminState);

    const authenticatedUser = useRecoilValue(authenticatedUserState);

    const handleBdmSliderOpen = useEditBdmSlider();

    if (canEditBdmInfo(isAdmin, authenticatedUser?.profile.name, option?.email)) {
        return <AutoCompleteOption
            option={option}
            onEdit={async (value: any) => {
                await handleBdmSliderOpen(option.value);
            }}
            inputProps={inputProps}
        />
    } else {
        return <AutoCompleteOption option={option} inputProps={inputProps}/>
    }
}