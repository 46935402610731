import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {exceptionErrorMessageState, validationErrorMessagesState} from "../State/Error";
import {
    appointmentDrawer,
    conferenceDrawer,
    contactDrawer,
    customerDrawer,
    editAppointmentDrawer, followUpReasonDrawer
} from "../State/Drawer";
import {customerOptions, selectedCustomerState} from "../State/Customer";
import {contactOptions} from "../State/Contact";
import {bdmOptions} from "../State/User";
import React, {useEffect, useRef, useState} from "react";
import {FormikProps} from "formik";
import {checkIfFollowUpMeeting, getUserCalendarAppointments} from "../Services/AppointmentService";
import moment, {Moment} from "moment";
import {IBdmAppointmentOptions, IFollowUpMeetingCheckModel} from "../Types/appointment";
import {toast} from "react-toastify";
import {conferenceOptions} from "../State/Conference";
import {followUpReasonOptions} from "../State/FollowUpReason";
import {AxiosResponse} from "axios";

export const useAppointmentForm = ({linkToExistingEvent, appointmentDateTime, users}: any) => {
    const formRef = useRef<FormikProps<any>>(null);

    const custOptions = useRecoilValue(customerOptions);
    const contOptions = useRecoilValue(contactOptions);
    const bdmsOptions = useRecoilValue(bdmOptions);
    const confOptions = useRecoilValue(conferenceOptions);
    const reasonOptions = useRecoilValue(followUpReasonOptions);

    const [customerDrawerIsOpen, setCustomerDrawer] = useRecoilState(customerDrawer);
    const [contactDrawerIsOpen, setContactDrawer] = useRecoilState(contactDrawer);
    const [conferenceDrawerIsOpen, setConferenceDrawer] = useRecoilState(conferenceDrawer);
    const [followUpReasonDrawerIsOpen, setFollowUpReasonDrawer] = useRecoilState(followUpReasonDrawer);
    const setAppointmentDrawer = useSetRecoilState(appointmentDrawer);
    const setEditAppointmentDrawer = useSetRecoilState(editAppointmentDrawer);

    const resetErrorMessages = useResetRecoilState(validationErrorMessagesState);
    const [exceptionErrorMessages, setExceptionErrorMessages] = useRecoilState(exceptionErrorMessageState);
    const [selectedCustomer, setSelectedCustomer] = useRecoilState(selectedCustomerState);

    const [userEvents, setUserEvents] = useState<IBdmAppointmentOptions[]>([]);
    const [showEventsSelection, setShowEventsSelection] = useState(linkToExistingEvent);
    const [dateValue, setDateValue] = useState<string>(appointmentDateTime);
    const [contacts, setContacts] = useState<number[]>([]);
    const [bdmSelection, setBdmSelection] = useState<{label:string, value:number}[]>(()=>{
        return users?.length ? [{
            label: users[0].name,
            value: users[0].userId
        }] : []
    });
    const [linkToExistingEventState, setLinkToExistingEventState] = useState(linkToExistingEvent);
    const [sendToContact, setSendToContact] = useState(true);
    const [pendingFollowUpCheck, setPendingFollowUpCheck] = useState(true);

    useEffect(()=>{
        const fetchBdmEvents = async (startDate: Moment, endDate: Moment) => {
            try {
                const data = await getUserCalendarAppointments(bdmSelection[0].value, startDate, endDate);
                return data.data;
            } catch(e){
                if (e.response?.data) {
                    setExceptionErrorMessages(e.response.data);
                }
                return null;
            }
        }

        if (linkToExistingEventState && dateValue && bdmSelection.length){
            (async function() {
                const dateSelected = moment(dateValue)

                const nextDay = moment(dateSelected).add(1, "day");

                const bdmEvents = await fetchBdmEvents(dateSelected, nextDay);

                if(bdmEvents !== null){
                    setUserEvents(
                        bdmEvents.map(event => {
                            if(moment(event.timeStart).isSame(dateSelected, "minute")){
                                formRef.current?.setFieldValue("googleEventId", event.eventId);
                            }
                            return {
                                label: event.title,
                                value: event.eventId
                            }
                        }));
                    setShowEventsSelection(true);
                } else {
                    setUserEvents([]);
                    toast.error("Failed to get your appointments")
                    setShowEventsSelection(false);
                }
                
            })()
        } else {
            setShowEventsSelection(false);
        }
    },[linkToExistingEventState, dateValue, bdmSelection])

    useEffect(() => {
        const getMeetingStatus = async (followUpCheckModel: IFollowUpMeetingCheckModel) => {
            try {
                const data: AxiosResponse = await checkIfFollowUpMeeting(followUpCheckModel);
                return data.data;
            } catch(e){
                if (e.response?.data) {
                    setExceptionErrorMessages(e.response.data);
                }
                return null;
            }
        }
        if (selectedCustomer && dateValue && contacts && contacts.length > 0) {
            (async function() {
                const isFollowUp = await getMeetingStatus({
                    appointmentDateTime: dateValue,
                    customerId: selectedCustomer,
                    contactIds: contacts
                })

                if (isFollowUp !== null) {
                    formRef?.current?.setFieldValue("isFollowUp", isFollowUp);
                }

                setPendingFollowUpCheck(false);
            })()
        }
    }, [contacts, dateValue, selectedCustomer])

    const handleClose = () => {
        setAppointmentDrawer(false);

        setEditAppointmentDrawer(false);

        resetErrorMessages();
    }

    const handleCustomerDrawer = () => {
        setCustomerDrawer(!customerDrawerIsOpen)
    }

    const handleContactDrawer = () => {
        setContactDrawer(!contactDrawerIsOpen)
    }

    const handleConferenceDrawer = () => {
        setConferenceDrawer(!conferenceDrawerIsOpen);
    }

    const handleBdmIdChange = (value: any)=>{
        setBdmSelection(value);
    }

    const handleDateChange = (e: React.FormEvent<HTMLInputElement>) =>{
        setDateValue(e.currentTarget.value);
    }

    const handleCustomerChange = (customer: any) =>{
        setSelectedCustomer(customer.value);
        formRef?.current?.setFieldValue("contactIds", []);
    }

    const handleFollowUpReasonDrawer = () => {
        setFollowUpReasonDrawer(!followUpReasonDrawerIsOpen);
    }

    const handleContactChange = (value: any) => {
        setContacts(value?.map((x: any) => x.value))
    }

    return {
        formRef,
        custOptions,
        handleCustomerDrawer,
        contOptions,
        handleContactDrawer,
        bdmsOptions,
        exceptionErrorMessages,
        handleClose,
        userEvents,
        linkToExistingEventState: [linkToExistingEventState, setLinkToExistingEventState],
        sendToContactState: [sendToContact, setSendToContact],
        showEventsSelection,
        handleDateChange,
        handleBdmIdChange,
        handleConferenceDrawer,
        confOptions,
        handleCustomerChange,
        reasonOptions,
        handleFollowUpReasonDrawer,
        handleContactChange,
        pendingFollowUpCheck
    };
}