import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, {useEffect} from 'react';
import BookAppointmentButton from '../Components/ui/buttons/BookAppointmentButton';
import LoginButton from '../Components/ui/buttons/LoginButton';
import {useLayoutStyles} from '../theme';
import {useSetRecoilState} from 'recoil';
import {appointmentsQueryParamState} from '../State/User';
import {AppointmentTrackerLayout} from '../Components/ui/Layout/AppointmentTrackerLayout';
import {ProtectProductLayout} from '../Components/ui/Layout/ProtectProductLayout';

const useStyles = makeStyles()(() => ({
	root: {
		background: 'url(/PF_Loop_still.png) top right no-repeat',
		backgroundAttachment: 'fixed',
		minHeight: '100vh',
	},
	buttonContainer: {
		justifyContent: 'center'
	}
}));

export const LandingPage = (props: any) => {

	const {productId} = props.match.params

	const setProtectProductId = useSetRecoilState(appointmentsQueryParamState);

	useEffect(() => {
		setProtectProductId(productId)
	}, [productId])

	const classes  = {
		...useLayoutStyles().classes,
		...useStyles().classes
	};

	let displayContent;

	switch (productId) {
		case '2':
			displayContent = <ProtectProductLayout img={'/RP_LeftAlign.png'} text={'Refund Protect'}/>;
			break;
		case '3':
			displayContent = <ProtectProductLayout img={'/PF_LeftAlign.png'} text={'Protect Financial'}/>;
			break;
		case '4':
			displayContent = <ProtectProductLayout img={'/EP_LeftAlign.png'} text={'Event Protect'}/>;
			break;
		default:
			displayContent = <AppointmentTrackerLayout/>;
	}

	return (
        <Grid container alignItems="center" justifyContent="center" className={classes.root}>
			<Grid item xs={12} lg={6}>
				{displayContent}
			</Grid>
			<Grid container item xs={12} lg={6} justifyContent="center">
				<Grid container item xs={12} lg={6} className={classes.p} justifyContent="center">
					<BookAppointmentButton productId={productId}/>
				</Grid>
				<Grid container item xs={12} lg={6} className={classes.p} justifyContent="center">
					<LoginButton/>
				</Grid>
			</Grid>
		</Grid>
    );
}
