import {atom} from "recoil";

export const editBdmState = atom<any>({
    key: 'bdmState',
    default: null
})

export const bdmEditedState = atom<number>({
    key: 'bdmEditedState',
    default: 0
})