import {getRegionFromValue} from "../Services/AppointmentService";
import {NavbarAutoComplete} from "../FormFields/NavbarAutoComplete";
import React from "react";
import {sectorOptions} from "../dropdownOptions";

export const SectorsDropDown = ({handleChange}: any) => {
    return <NavbarAutoComplete
        title="Sector"
        options={sectorOptions}
        handleChange={handleChange}
        disableClearable={true}
        defaultValue={{
            label: sectorOptions[0].label,
            value: sectorOptions[0].value
        }}
    />
}