import {SelectChangeEvent} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import {useAutoCompleteStyles} from "../theme";
import {FieldProps} from "formik";
import {IAppointmentModel} from "../Types/appointment";
import classNames from "classnames";
import {ValidationMessage} from "../Components/ValidationMessage";

interface SelectInputProps {
    name: string,
    options: {label: string, value: string}[],
    label: string,
    includeEmptyOption: boolean,
    fieldProps?: FieldProps<IAppointmentModel>,
    handleChange?: (value: any) => void,
    styles?:string[],
    multiple?: boolean,
    value?: any,
    validationProperty?: string
}

export const SelectInput = ({name, options, label, includeEmptyOption, fieldProps, handleChange, styles, multiple, value, validationProperty}: SelectInputProps) => {

    let selectOptions = options
    if (includeEmptyOption) {
        selectOptions = [
            {label: 'None', value: ''},
            ...options
        ]
    }

    const fieldValue = fieldProps?.form.getFieldProps(name).value
    const defaultValue = multiple === true ? [] : ''
    const inputValue = !!value ? value : (!!fieldValue || fieldValue === 0) ? fieldValue : defaultValue

    return (
        <>
            <Select
                name={name}
                label={label}
                className={classNames(...styles)}
                value={inputValue}
                placeholder={label}
                onChange={
                    (event: SelectChangeEvent) => {
                        if (fieldProps) {
                            fieldProps.form.setFieldValue(name, event.target.value)
                        }

                        if (handleChange) {
                            handleChange(event.target.value);
                        }
                    }
                }
                onBlur={(e) => {
                    fieldProps?.form.handleBlur(e);
                }}
                variant="standard"
                multiple={multiple === true}
            >
                {selectOptions.map((option: any, i: number)=>{
                    return <MenuItem value={option.value} key={i}>
                        {option.label}
                    </MenuItem>
                })}
            </Select>
            {validationProperty &&
            <ValidationMessage propertyName={validationProperty} props={fieldProps?.form} />
            }
        </>
    )
}