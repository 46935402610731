import { makeStyles } from 'tss-react/mui';

export const useAppointmentSlotCardStyles = (hovering: boolean) => makeStyles()(theme => ({
	root: {
		boxShadow: '-1px 8px 30px 0px rgba(88,88,88,0.2)',
		border: 'none',
		padding: theme.spacing(2),
		'&&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('xl')]: {
			padding: theme.spacing(1)
		}
	},
	selected: {
		backgroundColor: theme.palette.primary.main,
	},
	typographyPrimary: {
		color: hovering ? theme.palette.secondary.light : theme.palette.secondary.dark
	},
	typographySecondary: {
		color: hovering ? theme.palette.secondary.light : theme.palette.secondary.main
	}
}))();