import {useRecoilValue} from "recoil";
import {validationErrorMessagesState} from "../State/Error";

export const ValidationMessage = ({propertyName, props} : any) =>{
    const validationErrorMessages = useRecoilValue(validationErrorMessagesState);
    const error = props?.errors[propertyName];
    return (
        <div className='validation-message'>
            {validationErrorMessages && validationErrorMessages.find(x => x.property.toLowerCase() === propertyName.toLowerCase())?.errors}
            { error && props?.touched[propertyName] ? error : ''}
        </div>
    )
}