import {useRecoilValue} from 'recoil';
import {chosenAppointmentState} from '../State/AppointmentState';
import {IAppointmentFormModel} from '../Types/appointment';
import {IContactBookAppointmentFormModel, IContactFormModel} from '../Types/Contact';
import {BookingEngineType, CustomerProduct, ICustomerFormModel} from '../Types/Customer';
import {IConferenceModel} from "../Types/Conference";
import {IFollowUpReasonModel} from "../Types/FollowUpReason";

export const createAppointmentInitialValues: IAppointmentFormModel = {
	userIds: [],
	appointmentDateTime: '',
	method: null,
	duration: '',
	source: null,
	description: '',
	customerId: 0,
	contactIds: [],
	createdBy: '',
	methodId: null,
	sendToContact: true,
	conferenceId: null,
	linkToExistingEvent: false,
	googleEventId: '',
	location: null,
	followUpReasonId: null
}

export const EditAppointmentInitialValues = () => {

	const editValues = useRecoilValue(chosenAppointmentState)

	return {
		appointmentId: editValues.appointmentId,
		userIds: editValues.users.map(x => x.userId),
		appointmentDateTime: editValues.appointmentDateTime,
		method: editValues.method,
		duration: editValues.duration,
		source: editValues.source,
		description: editValues.description,
		customerId: editValues.customerId,
		status: editValues.status,
		contactIds: editValues.contacts.map(x => x.contactId),
		methodId: editValues.methodId,
		sendToContact: editValues.sendToContact,
		linkToExistingEvent: editValues.linkToExistingEvent,
		googleEventId: editValues.googleEventId
	}
}

export const customerInitialValues: ICustomerFormModel = {
	name: '',
	sector: '',
	products: [],
	website: '',
	region: 0,
	bookingEngineType: BookingEngineType.Unknown,
	bookingEngineName: ''
}

export const contactInitialValues: IContactFormModel = {
	name: '',
	phone: '',
	email: '',
	jobTitle: '',
	customerId: [],
}

export const contactBookAppointmentInitialValues: IContactBookAppointmentFormModel = {
	contactName: '',
	phone: '',
	email: '',
	company: '',
	contactTimezone: '',
	jobTitle: '',
	startTime: '',
	endTime: '',
	bdmName: '',
	day: '',
	productId: CustomerProduct.refundProtect,
	recaptchaToken: '',
	sector: 0,
	bdmId: -1
}

export const conferenceInitialValues: IConferenceModel = {
	id: -1,
	name: '',
	location: ''
}

export const followUpReasonInitialValues: IFollowUpReasonModel = {
	id: -1,
	name: ''
}