import axios, {AxiosResponse} from 'axios'
import {ICustomerEditFormModel, ICustomerFormModel, ICustomerModel} from '../Types/Customer'
import {rootUrl} from './AppointmentService'

export const getCustomers = async (): Promise<AxiosResponse<ICustomerModel[]>> => await axios.get<ICustomerModel[]>(`${rootUrl}/customers`)

export const createCustomer = async (customer: ICustomerFormModel) => await axios.post(`${rootUrl}/customers`, customer)

export const getCustomerById = async (customerId: number) => await axios.get(`${rootUrl}/customer/${customerId}`);

export const editCustomerById = async (customer: ICustomerEditFormModel) => await axios.put(`${rootUrl}/customer/${customer.customerId}`, customer);