import {FormControl, TextField} from '@mui/material'
import {useStyles} from './FormStyles'
import {ErrorMessage} from 'formik'
import {useAutoCompleteStyles} from '../theme';

export const DatePicker = ({name, type, value, onChange, onBlur}: any) => {

	const classes = {
		...useStyles().classes,
		...useAutoCompleteStyles().classes
	}

	return (
		<div className={classes.formControl}>
			<FormControl variant="standard">
				<TextField
					name={name}
					type={type}
					value={value || ''}
					className={classes.formField}
					onChange={onChange}
					onBlur={onBlur}
					variant="standard"
				/>
				<ErrorMessage className="errors" name={name}/>
			</FormControl>
		</div>
    );
}