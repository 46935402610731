import { makeStyles } from 'tss-react/mui';

export const layoutStyle = makeStyles()(theme => ({
	root: {},
	titleSection: {
		paddingTop: theme.spacing(10),
		paddingLeft: theme.spacing(10),
		[theme.breakpoints.down('xl')]: {
			paddingTop: theme.spacing(6),
			paddingLeft: theme.spacing(6),
			paddingRight: theme.spacing(6),
			paddingBottom: theme.spacing(4)
		}
	},
	titleText: {
		fontSize: 24,
		[theme.breakpoints.down('xl')]: {
			fontSize: 20

		},
		color: theme.palette.primary.main,
	},
	img: {
		maxWidth: '100%',
		paddingBottom: 40,
		[theme.breakpoints.up('lg')]: {
			maxWidth: 587,
			maxHeight: 178
		}
	}
}));