import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from 'tss-react/mui';
import protectIcon from '../img/protectGroupLogo.svg';
import {CreateAppointmentSlider} from './CreateAppointmentSlider';
import {useRecoilState, useRecoilValue} from 'recoil';
import {appointmentDrawer} from '../State/Drawer';
import {MainSearchBar} from '../FormFields/MainSearchBar';
import {appointmentPagingState} from '../State/AppointmentState';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, {useState} from 'react';
import {Region, AppointmentStatus} from '../Types/appointment';
import {userState} from '../State/User';
import {CustomerProduct} from '../Types/Customer';
import {BDMAutoCompleteOption} from "../FormFields/BDMAutoComplete";
import {NavbarAutoComplete} from "../FormFields/NavbarAutoComplete";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import classNames from "classnames";
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {EnumToDisplayString} from "../Helpers/Helpers";
import {getRegionFromValue} from "../Services/AppointmentService";
import {RegionsDropDown} from "./RegionsDropDown";
import {ProductsDropDown} from "./ProductsDropDown";

const useStyles = makeStyles()((theme) => ({
	grow: {
		flexGrow: 1,
	},
	toolbarColor: {
		backgroundColor: 'black'
	},
	toolbar: {
		alignItems: 'flex-start',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2),
	},
	searchBar: {
		marginLeft: '3%'
	},
	largeIcon: {
		width: 50,
		height: 50,
		position: 'absolute',
		right: '3%'
	},
	editIcon: {
		float: 'right',
		marginLeft: '10px',
		marginRight: '10px'
	},
	addIcon: {
		fontSize: '50px',
		marginLeft: '3%'
	},
	navbarInput: {
		color: '#8e8e93',
		background: 'white',
		width: 'auto',
		borderRadius: '6px',
		height: '37px',
		padding: '8px 16px 8px 12px',
		verticalAlign: 'middle',
		fontSize: '14px',
		cursor: 'pointer',
	},
	datePicker: {
		zIndex: 99,
		marginLeft: '3%',
		"& div": {
			border: '0'
		}
	}
}));

const defaultBdmTitle = 'BDM';
const defaultDateValue = ['', ''];

export const Navbar = () => {
	const [paging, setPaging] = useRecoilState(appointmentPagingState);

	const [bdmTitle, setBdmTitle] = useState(paging.user ? paging.user : defaultBdmTitle);

	const [appointmentDrawerIsOpen, setAppointmentDrawer] = useRecoilState(appointmentDrawer);


	const users = useRecoilValue(userState);

	const [date, setDate] = useState([paging.startDate, paging.endDate]);

	const handleDateChange =  (date: any) => {
		const startDate = date ? date[0] : '';
		const endDate = date ? date[1] : '';

		setDate([startDate, endDate])
		setPaging(curr => ({...curr, startDate: startDate, endDate: endDate, page: 0}))
	};

	const statusChange = (e: any) => {
		setPaging(curr => ({...curr, status: e.value, page: 0}))
	}

	const regionChange = (e: any) => {
		setPaging(curr => ({...curr, region: e.value, page: 0}))
	}

	const onUserSelect = (e: any) => {
		const user = users.find(user => user.userId == e?.value);
		const userName = user ? user.name : '';
		const title =  user ? userName : defaultBdmTitle;

		setBdmTitle(title);
		setPaging(curr => ({...curr, user: userName, page: 0}));
	}

	const onProductSelect = (e: any) => {
		setPaging(curr => ({...curr, product: e.value, page: 0}))
	}

	const { classes } = useStyles();

	return (
        <div className={classes.grow}>
			<AppBar position="static" className={classes.toolbarColor}>

				<Toolbar>
					<img
						height="75"
						width="75"
						src={protectIcon}
						alt={'Protect Group'}
					/>

					<CreateAppointmentSlider/>
					<MainSearchBar/>

					<DateRangePicker
						onChange={handleDateChange}
						value={date}
						className={classNames(classes.searchBar, classes.navbarInput, classes.datePicker)}
						clearIcon={date === defaultDateValue ? null : <ClearIcon color={'secondary'}/> }
						calendarIcon={<DateRangeIcon color={'secondary'}/>}
					/>


					<NavbarAutoComplete
						handleChange={statusChange}
						options={[
							{
								label: "All",
								value: AppointmentStatus.All.toString()
							},
							{
								label: "Pending",
								value: AppointmentStatus.Pending.toString()
							},
							{
								label: "Complete",
								value: AppointmentStatus.Complete.toString()
							},
							{
								label: "Cancelled",
								value: AppointmentStatus.Cancelled.toString()
							}
						]}
						disableClearable={true}
						title="Status"
						defaultValue={{
							label: AppointmentStatus[paging.status],
							value: paging.status.toString()
						}}
					/>

					<NavbarAutoComplete
						options={users?.map(user => ({label: user.name, value: user.userId}))}
						OptionsRender={BDMAutoCompleteOption}
						handleChange={onUserSelect}
						title={bdmTitle}
					/>

					<ProductsDropDown handleChange={onProductSelect} defaultValue={paging.product} />

					<RegionsDropDown handleChange={regionChange} defaultValue={paging.region} />

					<AddCircleIcon
						onClick={() => setAppointmentDrawer(!appointmentDrawerIsOpen)}
						fontSize={'inherit'}
						className={classes.addIcon}
					/>
				</Toolbar>
			</AppBar>
		</div>
    );
}