import {
    Box,
    Button,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {FeedbackQuestionSet} from "../Components/Appointment Feedback/FeedbackQuestionSet";
import {useAppointmentFeedback} from "../Hooks/useAppointmentFeedback";
import React from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {MeetingNotRelevantSelect} from "../Components/Appointment Feedback/MeetingNotRelaventSelect";


const appointmentFeedbackStyles = makeStyles()(theme => ({
	root: {
		minHeight: '100vh',
		background: 'url(/PF_Loop_still.png) top right no-repeat',
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(4)
	},
	header: {
		height: 'auto'
	}
}));

const AppointmentFeedback = () => {
	const { classes } = appointmentFeedbackStyles();
	const {
		meetingNotRelevant,
		handleMeetingNotRelevant,
		meetingNotRelevantReason,
		handleMeetingNotRelevantReasonChange,
		questionSets,
		handleQuestionToggle,
		score,
		handleSave,
		inProgress,
		mrrValue,
		handleMrrValueChange,
		proposalSent,
		handleProposalSentChange,
		feedbackComment,
		handleFeedbackComment
	} = useAppointmentFeedback();

	return (
        <Grid container className={classes.root}>
			<Grid item xs={4} container justifyContent="center" alignItems="center">
				<Grid item>
					<Typography variant="h1" color="primary">Feedback</Typography>
				</Grid>
				<Grid item>
					<Box paddingX={4}>
						<Typography color="primary" variant="h6">
							Please provide feedback by answering a few questions about the appointment
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid item xs={5} container justifyContent="center" alignItems="center">
				<Grid item container>
					<Box paddingY={2} width="50%">
					<FormControlLabel
						label={"Meeting not relevant"}
						control={
							<Switch
							checked={meetingNotRelevant}
							color={'primary'}
							onChange={handleMeetingNotRelevant}
							name={"meetingNotRelevant"}
							inputProps={{'aria-label': 'secondary checkbox'}}/>
						}
					/>
					<FormControlLabel
						label={"Was a proposal sent?"}
						control={
							<Switch
								checked={proposalSent}
								color={'primary'}
								onChange={handleProposalSentChange}
								name={"proposalSent"}
								inputProps={{'aria-label': 'secondary checkbox'}}/>
						}
					/>
					{
						(meetingNotRelevant && <MeetingNotRelevantSelect meetingNotRelevantReason={meetingNotRelevantReason} handleMeetingNotRelevantReasonChange={handleMeetingNotRelevantReasonChange} />)
					}
					{
						questionSets.map((x, i) => (
							<FeedbackQuestionSet key={`question-set-${i}`} questionSet={x} toggleSelected={handleQuestionToggle} meetingNotRelevant={meetingNotRelevant}/>
						))
					}
						<Box paddingTop={1} width="100%">
							<Grid container>
								<Grid item container>
									<FormControl variant="standard">
										<Typography variant="h6" color="primary">MRR Value</Typography>
										<Input
											id="mrr-value"
											value={mrrValue}
											onChange={handleMrrValueChange}
											startAdornment={<InputAdornment position="start">$</InputAdornment>}
											type="text"
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Box>
						<Box paddingY={2} width="100%">
							<Grid container>
								<Grid item container>
									<FormControl variant="standard">
										<Typography variant="h6" color="primary">Any Other Feedback Comments</Typography>
										<Input
											name={'description'}
											type={'text'}
											multiline={true}
											value={feedbackComment}
											onChange={handleFeedbackComment}
											inputProps={
												{
													maxLength: 500
												}
											}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Grid item xs={3} container alignItems="center" justifyContent="center">
				<Grid item xs={12}>
					<Typography variant="h3" color="primary">Score: {score}</Typography>
				</Grid>
			</Grid>
			<Grid item xs={12} container justifyContent="center">
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						size="large"
						onClick={handleSave}
						disabled={inProgress}
					>
						Submit
					</Button>
				</Grid>
			</Grid>
		</Grid>
    );
}

export default AppointmentFeedback
