import {BDMAutoCompleteOption} from "./BDMAutoComplete";
import classNames from "classnames";
import {Autocomplete, TextField} from "@mui/material";
import React from "react";
import {makeStyles} from "tss-react/mui";
import {AutoCompleteOption} from "./AutoCompleteOption";


const useStyles = makeStyles()((theme) => ({
    selectInput: {
        color: '#8e8e93',
        background: 'white',
        borderRadius: '6px',
        width: '175px',
        verticalAlign: 'middle',
        fontSize: '14px',
        cursor: 'pointer',
        lineHeight: '2',
        marginLeft: '3%',
        height: '50px',
        '& input':{
            fontSize: '14px'
        },
        '& .Mui-focused':{
            border:'none'
        }
    }
}));

export const NavbarAutoComplete = ({options, OptionsRender, extraStyles, handleChange, title, defaultValue, disableClearable=false}: any)=>{
    const { classes } = useStyles();

    let classesOutput;

    if (extraStyles) {
        classesOutput = classNames(classes.selectInput, ...extraStyles);
    } else {
        classesOutput = classNames(classes.selectInput)
    }

    return (
        <Autocomplete
            defaultValue={defaultValue}
            isOptionEqualToValue={(e: any, value: any) => e.value === value.value}
            getOptionLabel={(options) => options.label}
            options={options}
            renderOption={
                (props, option, state)=>
                    OptionsRender ?
                        <OptionsRender option={option} inputProps={props} key={`item-${option.value}`} /> :
                        <AutoCompleteOption option={option} inputProps={props} key={`item-${option.value}`}/>
            }
            className={classesOutput}
            onChange={(e, newValue)=>{
                handleChange(newValue)
            }}
            renderInput={(params)=>
                <div>
                    <TextField
                        {...params}
                        name={"bdm"}
                        fullWidth
                        variant="outlined"
                        placeholder={title}
                    />
                </div>
            }
            disableClearable={disableClearable}
        />
    )
}