import {Form, Formik} from 'formik';
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {Input} from '../FormFields/Input';
import {conferenceDrawer, editConferenceDrawer} from '../State/Drawer';
import {useSetRecoilState} from 'recoil';
import {conferenceValidationSchema} from '../FormikProps/Validation';
import {IConferenceModel} from "../Types/Conference";
import {useErrorMessages} from "../Hooks/useErrorMessages";

interface ConferenceFormProps {
    initialValues: IConferenceModel,
    onSubmit: any,
    label: string
}

export const ConferenceForm = ({initialValues, onSubmit, label}: ConferenceFormProps) => {
    const {
        resetAllErrorMessages,
        exceptionErrorMessage
    } = useErrorMessages()

    const setConferenceDrawer = useSetRecoilState(conferenceDrawer);

    const setEditConferenceDrawer = useSetRecoilState(editConferenceDrawer);

    const handleClose = () => {
        setConferenceDrawer(false);

        setEditConferenceDrawer(false);

        resetAllErrorMessages();
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={conferenceValidationSchema}
            onSubmit={onSubmit}
            validateOnMount
            enableReinitialize
        >
            {formProps => (
                <>
                    <Form>
                        <DialogTitle
                            id="form-dialog-title">
                            {label}
                        </DialogTitle>
                        <DialogContent>
                            <Input
                                name={'name'}
                                type={'text'}
                                label={'Name'}
                                validationProperty={'name'}
                                formProps={formProps}
                                value={initialValues.name}
                            />
                            <Input
                                name={'location'}
                                type={'text'}
                                label={'Location'}
                                validationProperty={'location'}
                                formProps={formProps}
                                value={initialValues.location}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                type="submit"
                                disabled={!formProps.isValid}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                        <div className='validation-message'>
                            {exceptionErrorMessage}
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    )
}