import {Grid, Typography} from '@mui/material';
import {useLayoutStyles} from '../../../theme';
import {layoutStyle} from '../Styles/landingPageLayoutStyles';

export const ProtectProductLayout = ({img, text}: any) => {

	const classes = {
		...layoutStyle().classes,
		...useLayoutStyles().classes
	}

	return (
		<Grid container item xs={12} lg={9} className={classes.titleSection}>
			<Grid item container>
				<img
					src={img}
					alt="Logo"
					className={classes.img}
				/>
			</Grid>
			<Grid item container xs={12} md={12} lg={12}>
				<Typography color="primary" className={classes.titleText}>
					Book an appointment with one of our Sales Team
				</Typography>
				<Typography color="primary" className={classes.titleText}>
					to find out more about {text} and how it can help your business.
				</Typography>
			</Grid>
		</Grid>
	)
}