import {useRecoilValue, useSetRecoilState} from 'recoil';
import {editConferenceDrawer} from '../State/Drawer';
import '../index.css';
import React from 'react';
import {AutoCompleteOption} from './AutoCompleteOption';
import {IAutoCompleteOptionElementProps} from "../Types/input";
import {editConferenceState} from "../State/Conference";
import {getConferenceById} from "../Services/ConferenceService";
import {isAdminState} from "../State/Auth";

export const ConferenceAutoCompleteOption = ({option, inputProps}: IAutoCompleteOptionElementProps) => {

    const setConference = useSetRecoilState(editConferenceState)

    const setEditConferenceDrawer = useSetRecoilState(editConferenceDrawer)

    const isAdmin = useRecoilValue(isAdminState);

    const handleEdit = async (value: any) => {
        const {data} = await getConferenceById(value)
        setConference(data);
        setEditConferenceDrawer(true);
    }

    return (
        isAdmin
            ? <AutoCompleteOption option={option} onEdit={handleEdit} inputProps={inputProps}/>
            : <AutoCompleteOption option={option} inputProps={inputProps}/>
    )
}