import { useEffect, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { appointmentsState } from '../State/AppointmentState';

export interface AppointmentsData {
    isError: boolean;
    isLoading: boolean;
    appointments: Array<any>;
    totalCount: number
}

export default function useAppointmentsData(): AppointmentsData {

    const { state, contents } = useRecoilValueLoadable<any>(appointmentsState);

    const [appointments, setAppointments] = useState([]);

    const [totalCount, setTotalCount] = useState(0);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {

        setIsLoading(state === 'loading');

        setIsError(state === 'hasError');

        if (state === 'hasValue' && contents !== null) {

            const { data: { items, totalCount } } = contents

            setAppointments(items)

            setTotalCount(totalCount)
        }
    }, [state, contents]);

    return { appointments, totalCount, isLoading, isError };
}
