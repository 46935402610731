import axios from "axios";
import {rootUrl} from "./AppointmentService";
import {IBdmFormModel} from "../Types/Bdm";
import {formatName} from "../Helpers/Helpers";

export const getBdmById = async (bdmId: number) => await axios.get(`${rootUrl}/user/${bdmId}`);

export const editBdmById = async (bdm: IBdmFormModel) => await axios.put(`${rootUrl}/user/${bdm.userId}`,bdm)

export const deleteBdm = async (bdmId: number) => await axios.delete(`${rootUrl}/user/${bdmId}`)

export const canEditBdmInfo = (isAdmin: boolean, authenticatedUser?: string, bdmName?: string) => {
    return isAdmin || formatName(authenticatedUser) === formatName(bdmName);
}