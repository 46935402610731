import {useRecoilValueLoadable} from 'recoil';
import {appointmentSlotsState} from '../State/User';
import {useEffect, useState} from 'react';
import {AppointmentSlots, IUserAvailableTimes} from '../Types/User';
import {AxiosResponse} from 'axios';
import useIntegrationAppointment from "./useIntegrationAppointment";

export const useGetAvailableAppointments = () => {
	useIntegrationAppointment();
	const {state, contents} = useRecoilValueLoadable<AxiosResponse<AppointmentSlots[]>>(appointmentSlotsState)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [data, setData] = useState<AppointmentSlots[]>([])

	useEffect(() => {
		const isValidResponse = (contents: any): contents is AxiosResponse<AppointmentSlots[]> => {
			return (<AxiosResponse<IUserAvailableTimes[]>>contents).data !== undefined;
		}

		setIsLoading(state === 'loading')

		if (contents && state === 'hasValue' && isValidResponse(contents)) {
			const {data} = (<AxiosResponse<AppointmentSlots[]>>contents)
			setData(data)
		}
	}, [state, contents])

	return {isLoading, data}
}
