import { Route, Switch } from 'react-router-dom';
import { Dashboard } from './Dashboard';

export const HomePage = () => {
    return (
        <Switch>
            <Route
                exact
                path="/dashboard"
                component={Dashboard}>
            </Route>
        </Switch>
    )
}