import {Form, Formik} from 'formik';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {Input} from '../FormFields/Input';
import {GenericAutoComplete} from '../FormFields/GenericAutoComplete';
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {customerOptions} from '../State/Customer';
import {contactDrawer, editContactDrawer} from '../State/Drawer';
import {contactValidationSchema} from '../FormikProps/Validation';
import {IContactFormModel} from '../Types/Contact';
import {exceptionErrorMessageState, validationErrorMessagesState} from '../State/Error';

interface ContactFormProps {
	initialValues: IContactFormModel,
	onSubmit: any,
	label: string
}


export const ContactForm = ({initialValues, onSubmit, label}: ContactFormProps) => {

	const resetValidationMessages = useResetRecoilState(validationErrorMessagesState);

	const resetExceptionMessages = useResetRecoilState(exceptionErrorMessageState);

	const exceptionMessage = useRecoilValue(exceptionErrorMessageState);

	const custOptions = useRecoilValue(customerOptions);

	const setContactDrawer = useSetRecoilState(contactDrawer);

	const setEditDrawer = useSetRecoilState(editContactDrawer);

	const handleClose = () => {
		setContactDrawer(false);

		setEditDrawer(false);

		resetValidationMessages();

		resetExceptionMessages();
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={contactValidationSchema}
			onSubmit={onSubmit}
			validateOnMount
		>
			{props => (
				<Form>
					<DialogTitle
						id="form-dialog-title">
						{label}
					</DialogTitle>
					<DialogContent>
						<Input
							name={'name'}
							type={'text'}
							label={'Name'}
							validationProperty={'name'}
							formProps={props}
							value={initialValues.name}
						/>
						<Input
							name={'email'}
							type={'text'}
							label={'Email'}
							validationProperty={'email'}
							formProps={props}
							value={initialValues.email}
						/>
						<Input
							name={'phone'}
							type={'text'}
							label={'Phone number'}
							validationProperty={'phone'}
							formProps={props}
							value={initialValues.phone}
						/>
						<Input
							name={'jobTitle'}
							type={'text'}
							label={'Job title'}
							validationProperty={'jobTitle'}
							formProps={props}
							value={initialValues.jobTitle}
						/>
						<GenericAutoComplete
							name={'customerId'}
							label={'Company'}
							placeholder={'Company'}
							options={custOptions}
							formProps={props}
							validationProperty={'customerId'}/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleClose}
							color="primary"
						>
							Cancel
						</Button>
						<Button
							color="primary"
							type="submit"
							disabled={!props.isValid}
						>
							Submit
						</Button>
					</DialogActions>
					<div className='validation-message'>
						{exceptionMessage}
					</div>
				</Form>
			)}
		</Formik>
	)
}