import { InputAdornment } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { useSetRecoilState } from "recoil"
import { appointmentPagingState } from "../State/AppointmentState"
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles()(theme => ({
    searchBar: {
        width: '20%',
        backgroundColor: 'white',
        marginLeft: '3%',
        borderRadius: '6px',
        padding: '8px 16px 8px 12px',
    },
    searchBarInput: {
        fontSize: '14px',
        color: '#8e8e93',
    },
    icon : {
        cursor: 'pointer'
    }
}));

export const MainSearchBar = (): any => {

    const setPaging = useSetRecoilState(appointmentPagingState);

    const [searchTerm, setSearchTerm] = useState('')

    const { classes } = useStyles();

    let timeout: NodeJS.Timeout;

    const handleChange = (searchTerm: string) => {
        setSearchTerm(searchTerm)
    }

    useEffect(() => {

        timeout = setTimeout(async () => {

            setPaging((current: any) => ({ ...current, filter: searchTerm }))

        }, 2000)

        return () => clearTimeout(timeout)
    }, [searchTerm])

    return (
        <TextField
            className={classes.searchBar}
            placeholder={"Search by Contacts, Company, or Booked by"}
            variant="standard"
            onChange={(event)=>{
                handleChange(event.target.value)
            }}
            value={searchTerm}
            InputProps={{
                inputProps: {
                    className: classes.searchBarInput
                },
                startAdornment:
                    <>
                        <InputAdornment
                        position="start">
                            <SearchIcon className={classes.icon}/>
                        </InputAdornment>
                    </>
            }}
        />
    )
}