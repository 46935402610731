import axios from 'axios'
import {rootUrl} from './AppointmentService'

export const getUsers = async () => await axios.get(`${rootUrl}/users`)

export const checkUserExists = async (email?: string) => {
	return await axios.get(`${rootUrl}/userexists`, {params: {email}})
}

export const checkTimezone = async (values: any) => {
	return await axios.post(`${rootUrl}/timezone`, values)
}
