import {FieldProps} from 'formik';
import {IAppointmentModel} from './appointment';

export interface ICustomerFormModel {
	name: string,
	sector: string,
	products: CustomerProduct[],
	website: string,
	region: number,
	bookingEngineType: BookingEngineType
	bookingEngineName: string
}

export interface ICustomerEditFormModel extends ICustomerFormModel {
	customerId: number
}

export interface ICustomerModel {
	customerId: number
	name: string,
	sector: CustomerSector,
	products: CustomerProduct[],
	website: string,
	region: number
}

export interface CustomerSliderProps {
	fieldProps: FieldProps<IAppointmentModel>
}

export enum CustomerProduct {
	all = 1,
	refundProtect = 2,
	protectFinancial = 3,
	eventProtect = 4
}

export enum ProtectProduct {
	'Refund Protect' = 2,
	'Protect Financial' = 3,
	'Event Protect' = 4
}

export enum IntegrationProducts {
	'Event Protect' = 1,
	'Refund Protect' = 2,
	'Dynamic' = 3,
}

export enum CustomerSector {
	transport = 0,
	hospitality = 1,
	venuesAndEvents = 2,
	sport = 3,
	tours = 4,
	massParticipation = 5,
	protectFinancial = 6,
	brokerIntroducer = 7
}

export enum BookingEngineType {
	Unknown = 0,
	InHouse = 1,
	Other = 2
}
