import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {getIntegrationMember} from "../Services/AppointmentService";
import {IContactBookAppointmentFormModel} from "../Types/Contact";
import {useSetRecoilState} from "recoil";
import {appointmentsQueryManagerState} from "../State/User";
import {integrationProductAvailable} from "../State/AppointmentState";
import {IntegrationProducts, ProtectProduct} from "../Types/Customer";

export default function useIntegrationAppointment() {
    const [member, setMember] = useState<IContactBookAppointmentFormModel>()
    const setManager = useSetRecoilState(appointmentsQueryManagerState)
    const setIntegrationProducts = useSetRecoilState(integrationProductAvailable)
    const { search } = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(search)
        const id = params.get('integrationId') ?? 0

        const fetchMember = async () => {
            const data = await getIntegrationMember(+id);
            const memberData = data.data
            if (memberData) {
                setMember({
                    ...memberData,
                    phone: memberData.telephone,
                    company: memberData.memberName,
                    companyId: memberData.appointmentCompanyId
                })
                setManager(memberData.commercialManager)
                setIntegrationProducts(memberData.products.map((p: any) => {
                    const isRefundProduct = p.productId === IntegrationProducts["Refund Protect"]
                        || p.productId === IntegrationProducts.Dynamic
                    if (isRefundProduct) {
                        return ProtectProduct["Refund Protect"]
                    }
                    return ProtectProduct["Event Protect"]
                }))
            }
        }

        if (id) {
            (async function() {
                await fetchMember()
            })()
        }
    }, [search])

    return {
        member
    }
}