import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';
import {signInRedirectCallback} from '../Services/user-service';
import {authenticatedUserState} from '../State/Auth';
import CircularProgress from '@mui/material/CircularProgress';
import {checkUserExists} from '../Services/UserService';
import {User} from 'oidc-client';

export const SignInRedirect = () => {
	const history = useHistory();

	const setUser = useSetRecoilState(authenticatedUserState);

	useEffect(() => {
		const getPath = async (user: null | User) => {
			const authUser = user as User

			if (authUser === null) {
				return '/unauthorized'
			}

			const { profile: { Roles, name } } = authUser

			if (!Roles) {
				return '/unauthorized'
			}

			const hasRole = Roles.indexOf('AppointmentTrackerUser') > -1

			if (!hasRole) {
				return '/unauthorized'
			}

			try {
				const { data } = await checkUserExists(name)

				if (data !== true) {
					return '/setup'
				}
			} catch (e) {
				return '/setup'
			}

			return '/dashboard'
		}

		(async () => {
			try {
				const user = await signInRedirectCallback();
				const path = await getPath(user)
				setUser(user)
				history.push(path)
			} catch (e) {
				console.log('redirect error', e)
			}
		})()
	}, []);

	return <div className={'loading'}>
		<CircularProgress color={'primary'}/>
	</div>
}
