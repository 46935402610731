import {atom, selector} from 'recoil';
import {BookingEngineType, ICustomerFormModel, ICustomerModel} from '../Types/Customer';

export const customersState = atom<ICustomerModel[]>({
	key: 'customers',
	default: []
})

export const editCustomerState = atom<any>({
	key: 'customerState',
	default: null
})

export const customerEditedState = atom<number>({
	key: 'customerEditedState',
	default: 0
})

export const editCustomerFormState = atom<ICustomerFormModel>({
	key: 'editCustomerFormState',
	default: {
		name: '',
		sector: '',
		products: [],
		website: '',
		region: 0,
		bookingEngineType: BookingEngineType.Unknown,
		bookingEngineName: ''
	}
})

export const selectedCustomerState = atom<number | null>({
	key: 'selectedCustomerState',
	default: null
})

export const editCustomer = selector({
	key: 'editCustomer',
	get: ({get}) => {
		const editState = get(editCustomerFormState);
		return editState;
	}
})

export const customerOptions = selector({
	key: 'customerOptions',
	get: ({get}) => {
		const customers = get(customersState);
		return customers.map((x: any) => {
			return {label: x.name, value: x.customerId};
		}).sort((a: any, b: any) => {
			if (a.label < b.label) {
				return -1;
			}
			if (a.label > b.label) {
				return 1;
			}
			return 0;
		})
	}
})