import {atom, selector} from "recoil";
import {IConferenceModel} from "../Types/Conference";
import {conferenceInitialValues} from "../FormikProps/InitialValues";

export const conferencesState = atom<IConferenceModel[]>({
    key: 'conferences',
    default: []
})

export const editConferenceState = atom<IConferenceModel>({
    key: 'conferenceEditState',
    default: conferenceInitialValues
})

export const conferenceOptions = selector({
    key: 'conferenceOptions',
    get: ({get}) => {
        const conferences = get(conferencesState);
        return conferences.map((x: any) => {
            return {label: x.name, value: x.id};
        }).sort((a: any, b: any) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        })
    }
})