import { Button } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	root: {
		borderRadius: 25,
		minWidth: 250,
		letterSpacing: 2,
		lineHeight: 1.7,
		paddingTop: '0.9rem',
		paddingBottom: '0.9rem',
		paddingLeft: '1.25rem',
		paddingRight: '1.25rem',
		fontSize: '0.56rem',
		width: 'auto'
	},
	contained: {
		boxShadow: 'none',
		backgroundColor: '#fff',
		border: '1px solid #010102',
		color: '#010102',
		'&&:hover': {
			backgroundColor: '#010102',
			color: '#fff',
			boxShadow: 'none'
		}
	}
}));

export default function SignInButton() {
	const { classes } = useStyles()
	return (
		<Button
			variant="contained"
			href="/login"
			classes={{
				root: classes.root,
				contained: classes.contained
			}}
		>
			Login
		</Button>
	)
}