import {Form, Formik} from 'formik';
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {Input} from '../FormFields/Input';
import {editFollowUpReasonDrawer, followUpReasonDrawer} from '../State/Drawer';
import {useSetRecoilState} from 'recoil';
import {followUpReasonValidationSchema} from '../FormikProps/Validation';
import {useErrorMessages} from "../Hooks/useErrorMessages";
import {IFollowUpReasonModel} from "../Types/FollowUpReason";

interface FollowUpReasonFormProps {
    initialValues: IFollowUpReasonModel,
    onSubmit: any,
    label: string
}

export const FollowUpReasonForm = ({initialValues, onSubmit, label}: FollowUpReasonFormProps) => {
    const {
        resetAllErrorMessages,
        exceptionErrorMessage
    } = useErrorMessages()

    const setFollowUpReasonDrawer = useSetRecoilState(followUpReasonDrawer);

    const setEditFollowUpReasonDrawer = useSetRecoilState(editFollowUpReasonDrawer);

    const handleClose = () => {
        setFollowUpReasonDrawer(false);

        setEditFollowUpReasonDrawer(false);

        resetAllErrorMessages();
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={followUpReasonValidationSchema}
            onSubmit={onSubmit}
            validateOnMount
            enableReinitialize
        >
            {formProps => (
                <>
                    <Form>
                        <DialogTitle
                            id="form-dialog-title">
                            {label}
                        </DialogTitle>
                        <DialogContent>
                            <Input
                                name={'name'}
                                type={'text'}
                                label={'Name'}
                                validationProperty={'name'}
                                formProps={formProps}
                                value={initialValues.name}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                type="submit"
                                disabled={!formProps.isValid}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                        <div className='validation-message'>
                            {exceptionErrorMessage}
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    )
}