import {Navbar} from '../Components/Navbar'
import {AppointmentList} from '../Components/AppointmentList'
import {toast, ToastContainer} from 'react-toastify';
import '../css/Dashboard.css'
import 'react-toastify/dist/ReactToastify.css';
import {useEffect} from 'react';
import {getUsers} from '../Services/UserService';
import {getCustomers} from '../Services/CustomerService';
import {getContacts} from '../Services/ClientService';
import {useSetRecoilState} from 'recoil';
import {userState} from '../State/User';
import {customersState} from '../State/Customer';
import {contactsState} from '../State/Contact';
import {conferencesState} from "../State/Conference";
import {getConferences} from "../Services/ConferenceService";
import {followUpReasonsState} from "../State/FollowUpReason";
import {getFollowUpReasons} from "../Services/FollowUpReasonService";

export const Dashboard = () => {

	const setUsers = useSetRecoilState(userState);

	const setCustomerOptions = useSetRecoilState(customersState);

	const setContactOptions = useSetRecoilState(contactsState);

	const setConferenceOptions = useSetRecoilState(conferencesState);

	const setFollowUpReasonOptions = useSetRecoilState(followUpReasonsState)

	const loadUsersAsync = async () => {
		try {
			const {data} = await getUsers();

			setUsers(data);
		} catch {
			toast.error('Unable to retrieve users.');
		}
	}

	const loadCustomersAsync = async () => {
		try {
			const {data} = await getCustomers();

			setCustomerOptions(data);
		} catch {
			toast.error('Unable to retrieve customers.');
		}
	}

	const loadContactsAsync = async () => {
		try {
			const {data} = await getContacts();

			setContactOptions(data);
		} catch {
			toast.error('Unable to retrieve contacts.');
		}
	}

	const loadConferencesAsync = async () => {
		try{
			const {data} = await getConferences();
			setConferenceOptions(data);
		} catch {
			toast.error('Unable to retrieve conferences.');
		}

	}

	const loadFollowUpReasonsAsync = async () => {
		try {
			const {data} = await getFollowUpReasons();
			setFollowUpReasonOptions(data);
		} catch {
			toast.error('Unable to retrieve follow up meeting options')
		}
	}

	useEffect(() => {

		(async () => {
			await Promise.all([
				loadUsersAsync(),
				loadCustomersAsync(),
				loadContactsAsync(),
				loadConferencesAsync(),
				loadFollowUpReasonsAsync()
			]);
		})()

	}, [])

	return (
		<div className="container">
			<ToastContainer autoClose={5000}/>
			<Navbar/>
			<AppointmentList/>
		</div>
	)
}