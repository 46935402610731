import {useHistory, useParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {FeedbackType, Question, QuestionSet} from "../Types/AppointmentFeedback";
import {submitFeedback} from "../Services/AppointmentService";
import {toast} from "react-toastify";

type Params = {
    appointmentId: string
}

const questionSetData: QuestionSet[] = [
    {
        title: 'Quality of Booking',
        questions: [
            {
                id: 1,
                text: 'Bookable Online?',
                score: 2,
                isSelected: false,
                disabled: false,
                type: FeedbackType.BookableOnline
            },
            {
                id: 2,
                text: 'Above Minimum Value?',
                score: 1,
                isSelected: false,
                disabled: false,
                type: FeedbackType.AboveMinimumValue
            }
        ]
    },
    {
        title: 'Quality of Decision Maker',
        questions: [
            {
                id: 3,
                text: 'Booked With The Correct Decision Maker?',
                score: 3,
                isSelected: false,
                disabled: false,
                type: FeedbackType.BookedWithCorrectDecisionMaker
            },
            {
                id: 4,
                text: 'Did The Prospect Understand The Service?',
                score: 2,
                isSelected: false,
                disabled: false,
                type: FeedbackType.UnderstoodTheService
            }
        ]
    },
    {
        title: 'Data Quality',
        questions: [
            {
                id: 5,
                text: 'All Custom Fields Completed?',
                score: 0.5,
                isSelected: false,
                disabled: false,
                type: FeedbackType.AllFieldsCompleted
            },
            {
                id: 6,
                text: 'Status Of Lead Updated?',
                score: 0.5,
                isSelected: false,
                disabled: false,
                type: FeedbackType.StatusOfLeadUpdated
            },
            {
                id: 7,
                text: 'Opportunity Created?',
                score: 0.5,
                isSelected: false,
                disabled: false,
                type: FeedbackType.OpportunityCreated
            },
            {
                id: 8,
                text: 'Clean Notes On The Opportunity?',
                score: 0.5,
                isSelected: false,
                disabled: false,
                type: FeedbackType.CleanNotes
            }
        ]
    }
]

export const useAppointmentFeedback = () => {
    const { appointmentId } = useParams<Params>()
    const history = useHistory()
    const [questionSets, setQuestionSets] = useState<QuestionSet[]>(questionSetData)
    const [score, setScore] = useState<number>(0)
    const [inProgress, setInProgress] = useState<boolean>(false)
    const [meetingNotRelevant, setMeetingNotRelevant] = useState<boolean>(false);
    const [meetingNotRelevantReason, setMeetingNotRelevantReason] = useState<number>(1);
    const [mrrValue, setMrrValue] = useState<string>('0');
    const [proposalSent, setProposalSent] = useState<boolean>(false);
    const [feedbackComment, setFeedbackComment] = useState<string>();

    const calculateScore = useCallback(()=>{
        return questionSets
            .flatMap(x => x.questions)
            .filter(x => x.isSelected)
            .reduce((c, n) => c + n.score, 0);
    },[questionSets]);

    useEffect(() => {
        setScore(calculateScore());
    }, [questionSets])

    const handleQuestionToggle = (id: number) => {
        setQuestionSets(curr => {
            const setIndex = curr.findIndex(x => x.questions.find(xx => xx.id === id) !== undefined)
            const questionIndex = curr[setIndex].questions.findIndex(x => x.id === id)
            const isSelected = curr[setIndex].questions[questionIndex].isSelected

            const question: Question = {
                ...curr[setIndex].questions[questionIndex],
                isSelected: !isSelected
            }

            const set: QuestionSet = {
                ...curr[setIndex],
                questions: [
                    ...curr[setIndex].questions.slice(0, questionIndex),
                    question,
                    ...curr[setIndex].questions.slice(questionIndex + 1)
                ]
            }

            return [
                ...curr.slice(0, setIndex),
                set,
                ...curr.slice(setIndex + 1)
            ]
        })
    }

    const handleMeetingNotRelevant = (event: React.ChangeEvent<HTMLInputElement>) => {
        let switchToggled = event.target.checked;
        setScore(switchToggled ? 0 : calculateScore());
        setMeetingNotRelevant(switchToggled);
    }

    const handleSave = async () => {
        try {
            setInProgress(true)
            await submitFeedback(parseInt(appointmentId), {
                score: score,
                meetingNotRelevant: meetingNotRelevant,
                meetingNotRelevantReason: meetingNotRelevantReason,
                mrrValue: parseInt(mrrValue.replace(/,/g, '')),
                proposalSent: proposalSent,
                questionsFeedback: questionSets
                    .flatMap(x => x.questions)
                    .map((value, index) => {
                        return {
                            feedbackType: value.type,
                            checked: value.isSelected
                        }
                    }),
                feedbackComment: feedbackComment
            })
            history.push('/dashboard')
        } catch (e) {
            toast.error('Unable to save feedback');
        } finally {
            setInProgress(false)
        }
    }

    const handleMeetingNotRelevantReasonChange = (value: number) => {
        setMeetingNotRelevantReason(value);
    }

    const handleMrrValueChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
        const fieldValue = event.target.value;

        const formattedValue =  fieldValue === '' ? '0' :
            parseInt(
                fieldValue.replace(/\D/g,'')
            ).toLocaleString("en-US");

        setMrrValue(formattedValue);
    }

    const handleProposalSentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProposalSent(event.target.checked);
    }

    const handleFeedbackComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeedbackComment(event.target.value);
    }

    return {
        meetingNotRelevant,
        handleMeetingNotRelevant,
        meetingNotRelevantReason,
        handleMeetingNotRelevantReasonChange,
        questionSets,
        handleQuestionToggle,
        score,
        handleSave,
        inProgress,
        mrrValue,
        handleMrrValueChange,
        proposalSent,
        handleProposalSentChange,
        feedbackComment,
        handleFeedbackComment
    };
}