import { useEffect } from "react"
import { signInRedirect } from "../Services/user-service"

export const Login = () => {
    
    useEffect(() => {
        signInRedirect()
            .then(() => {
            });
    }, []);
    return <></>
}