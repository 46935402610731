import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {Field, FieldProps} from 'formik';
import React, {useState} from 'react';
import {IAppointmentFormModel} from '../Types/appointment';
import {useStyles} from './FormStyles';
import classNames from "classnames";

export const YesNoSwitch = ({name, formProps, label, componentState}: any) => {

	const [state, setState] = componentState;

	const handleChange = () => {
		setState(!state);
		formProps.setFieldValue(name, !state)
	};

	const {classes} = useStyles();

	return (
		<div className={classNames(classes.formControl, classes.switchField)}>
			<FormControl variant="standard">
				<Field>
					{(fieldProps: FieldProps<IAppointmentFormModel>) => {
						return (
							<FormControlLabel
									label={label}
									control={
										<Switch
											checked={state}
											color={'primary'}
											onChange={handleChange}
											name={name}
											value={fieldProps.form.getFieldProps(name).value}
											inputProps={{'aria-label': 'secondary checkbox'}}
										/>
									}
							/>
						)
					}}
				</Field>
			</FormControl>
		</div>
    );
}