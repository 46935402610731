import FormControl from '@mui/material/FormControl';
import {useStyles} from './FormStyles'
import {useAutoCompleteStyles} from '../theme';
import {ValidationMessage} from "../Components/ValidationMessage";
import React from "react";
import TextField from '@mui/material/TextField';

export const Input = ({name, type, label, multiline, validationProperty, formProps}: any) => {

	const classes = {
		...useStyles().classes,
		...useAutoCompleteStyles().classes
	}

	let value = formProps.getFieldProps(name).value;

	return (
		<div className={classes.formControl}>
			<FormControl variant="standard">
				<TextField
					name={name}
					type={type}
					className={classes.formField}
					label={label}
					multiline={multiline}
					rows={4}
					variant="standard"
					onChange={(event)=>{
						formProps.setFieldValue(name, event.target.value)
					}}
					value={value}
					onBlur={(e) => {
						formProps.handleBlur(e);
					}}
				/>
			</FormControl>
			{validationProperty &&
			<ValidationMessage propertyName={validationProperty} props={formProps} />
			}
		</div>
    );
}