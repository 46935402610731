import {Drawer} from '@mui/material';
import {useRecoilState} from 'recoil';
import {rescheduleDrawer} from '../State/Drawer';
import {RescheduleForm} from "./RescheduleForm";

export const RescheduleSlider = () => {

    const [rescheduleDrawerIsOpen, setRescheduleDrawer] = useRecoilState(rescheduleDrawer);

    return (
        <>
            <Drawer
                onClose={() => setRescheduleDrawer(false)}
                open={rescheduleDrawerIsOpen}
                anchor="right"
                variant="temporary"
            >
                <RescheduleForm/>
            </Drawer>
        </>
    )

}