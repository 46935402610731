import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {editBdmDrawer} from "../State/Drawer";
import {bdmEditedState, editBdmState} from "../State/Bdm";
import {exceptionErrorMessageState, validationErrorMessagesState} from "../State/Error";
import {IBdmFormModel} from "../Types/Bdm";
import {editBdmById} from "../Services/BdmService";
import {toast} from "react-toastify";
import {useErrorMessages} from "./useErrorMessages";

export const useBdmSlider = ()=>{
    const [editBdmDrawerIsOpen, setEditBdmDrawer] = useRecoilState(editBdmDrawer);

    const [edit, setEdit] = useRecoilState(bdmEditedState);

    const {
        resetAllErrorMessages,
        handleErrorResponse
    } = useErrorMessages();

    const bdmValue = useRecoilValue(editBdmState);

    const onSubmit = async (values: IBdmFormModel) => {

        try {

            await editBdmById(values);

            toast.success('BDM successfully edited');

            setEdit(edit + 1);

            setEditBdmDrawer(false);

            resetAllErrorMessages();

        } catch (e) {
            handleErrorResponse(e);
            toast.error('BDM update failed');
        }
    };

    const handleClose = () => {
        setEditBdmDrawer(false);

        resetAllErrorMessages();
    }

    return [editBdmDrawerIsOpen, handleClose, bdmValue, onSubmit] as const;
};