import {useRecoilValue, useSetRecoilState} from 'recoil';
import {editFollowUpReasonDrawer} from '../State/Drawer';
import '../index.css';
import React from 'react';
import {AutoCompleteOption} from './AutoCompleteOption';
import {IAutoCompleteOptionElementProps} from "../Types/input";
import {isAdminState} from "../State/Auth";
import {editFollowUpReasonState} from "../State/FollowUpReason";
import {getFollowUpReasonById} from "../Services/FollowUpReasonService";

export const FollowUpReasonAutoCompleteOption = ({option, inputProps}: IAutoCompleteOptionElementProps) => {

    const setFollowUpReason = useSetRecoilState(editFollowUpReasonState)

    const setFollowUpReasonDrawer = useSetRecoilState(editFollowUpReasonDrawer)

    const isAdmin = useRecoilValue(isAdminState);

    const handleEdit = async (value: any) => {
        const {data} = await getFollowUpReasonById(value)
        setFollowUpReason(data);
        setFollowUpReasonDrawer(true);
    }

    return (
        isAdmin
            ? <AutoCompleteOption option={option} onEdit={handleEdit} inputProps={inputProps}/>
            : <AutoCompleteOption option={option} inputProps={inputProps}/>
    )
}