import {useSetRecoilState} from 'recoil';
import {editCompanyDrawer} from '../State/Drawer';
import '../index.css';
import React from 'react';
import {editCustomerState} from '../State/Customer';
import {getCustomerById} from '../Services/CustomerService';
import {AutoCompleteOption} from './AutoCompleteOption';
import {IAutoCompleteOptionElementProps} from "../Types/input";

export const CompanyAutoCompleteOption = ({option, inputProps}: IAutoCompleteOptionElementProps) => {
	const setCustomer = useSetRecoilState(editCustomerState)
	const setEditCustomerDrawer = useSetRecoilState(editCompanyDrawer)

	const handleEdit = async (value: any) => {
		const {data} = await getCustomerById(value)
		setCustomer(data);
		setEditCustomerDrawer(true);
	}

	return (
		<AutoCompleteOption option={option} onEdit={handleEdit} inputProps={inputProps}/>
	)
}