import {atom, selector} from 'recoil';
import {getAppointments} from '../Services/AppointmentService';
import {
	AppointmentSource,
	AppointmentStatus,
	Duration,
	IAppointmentFormEditModel,
	IAppointmentModel,
	IRescheduleModel,
	Region
} from '../Types/appointment';
import {loggedInUserState} from './User';
import {IPaging, PAGING_STORAGE_KEY} from '../Types/input';
import {CustomerProduct} from "../Types/Customer";

const localSessionEffect = (key: string) => ({setSelf, onSet}: any) => {
	const savedValue = localStorage.getItem(key)

	if (savedValue !== null) {
		setSelf(JSON.parse(savedValue));
	}

	onSet((newValue: any, defaultValue: any, isReset: boolean) => {
		isReset
			? localStorage.removeItem(key)
			: localStorage.setItem(key, JSON.stringify(newValue));
	});
};


export const appointmentState = atom<IAppointmentModel[]>({
	key: 'appointmentState',
	default: []
})

export const reloadAppointmentState = atom<number>({
	key: 'reloadAppointmentState',
	default: 0
})

export const appointmentsState = selector({
	key: 'AppointmentsState',
	get: async ({get}) => {
		const paging = get(appointmentPagingState);
		const loggedInUser = get(loggedInUserState);
		get(reloadAppointmentState)
		return await getAppointments(paging, loggedInUser.email);
	}
})

export const editAppointmentState = atom<IAppointmentFormEditModel>({
	key: 'EditAppointmentState',
	default: {
		appointmentId: 0,
		users: [],
		appointmentDateTime: '',
		method: '',
		source: AppointmentSource.linkedin,
		description: '',
		duration: Duration.fifteen,
		customerId: 0,
		status: AppointmentStatus.Pending,
		contacts: [],
		methodId: 0,
		sendToContact: true,
		conferenceName: '',
		linkToExistingEvent: false,
		googleEventId: '',
		location: '',
		followUpReasonId: null
	}
})

export const appointmentEditedState = atom<number>({
	key: 'appointmentEditedState',
	default: 0
})

export const deleteAppointmentState = atom<number>({
	key: 'DeleteAppointmentState',
	default: 0
})

export const createdAppointmentState = atom({
	key: 'CreatedAppointmentState',
	default: ''
})

export const appointmentPaginationDefaults = {
	pageSize: 10,
	page: 0,
	filter: '',
	count: 0,
	startDate: '',
	endDate: '',
	user: '',
	product: CustomerProduct.all,
	status: AppointmentStatus.Pending,
	region: Region.All
}

export const appointmentPagingState = atom<IPaging>({
	key: 'AppointmentPagingState',
	default: appointmentPaginationDefaults,
	effects: [
		localSessionEffect(PAGING_STORAGE_KEY)
	]
})

export const appointmentCountState = atom<number>({
	key: 'AppointmentCountState',
	default: 0
})

export const errorMessageState = atom<string>({
	key: 'ErrorMessageState',
	default: ''
})

export const chosenAppointmentState = selector({
	key: 'ChosenAppointmentState',
	get: ({get}) => {
		const edit = get(editAppointmentState);
		const mapped = {
			...edit,
			contactIds: edit.contacts.map(x => x.contactId),
			userIds: edit.users.map(x => x.userId),
			isFollowUp: edit.followUpReasonId !== null
		}
		return mapped
	}
})

export const toDeleteIdState = selector({
	key: 'ToDeleteId',
	get: ({get}) => {
		const deleteId = get(deleteAppointmentState);
		return deleteId
	}
})

export const appointmentGetCountState = selector({
	key: 'appointmentGetCountState',
	get: ({get}) => {
		const contacts = get(appointmentCountState);
		return contacts
	}
})

export const integrationProductAvailable = atom<number[]>({
	key: 'integrationProductAvailable',
	default: []
})

export const rescheduleAppointmentState = selector({
	key: 'RescheduleAppointmentState',
	get: ({get}) => {
		const edit = get(editAppointmentState);
		const mapped: IRescheduleModel = {
			appointmentId: edit.appointmentId,
			startDate: edit.appointmentDateTime,
			duration: edit.duration
		}
		return mapped
	}
})