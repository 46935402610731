import * as Yup from 'yup'
import {AppointmentMethod} from "../Types/appointment";
import {BookingEngineType} from "../Types/Customer";

export const appointmentValidationSchema = Yup.object({
	userIds: Yup.array().required('At least 1 BDM is required').min(1).nullable(),
	appointmentDateTime: Yup.string().required('Date and time is required'),
	method: Yup.number()
		.transform((value) => (isNaN(value) ? undefined : value))
		.required('Appointment method is required'),
	duration: Yup.string().required('Enter length of appointment'),
	source: Yup.number().required('Enter appointment source').nullable(),
	description: Yup.string().required('Description is required'),
	customerId: Yup.number().required('Company is required'),
	contactIds: Yup.array().required('Contacts are required').min(1).nullable(),
	googleEventId: Yup.string()
		.when('linkToExistingEvent', {
			is: (linkToExistingEvent: boolean) => linkToExistingEvent,
			then: Yup.string().required('Existing event is required'),
			otherwise:  Yup.string().nullable()
		}),
	methodId: Yup.number()
		.when('method', {
			is: (method:number) => method !== null && method !== AppointmentMethod.conference && method !== AppointmentMethod.faceToFace,
			then: Yup.number()
				.transform((value) => (isNaN(value) ? undefined : value))
				.required('Appointment location is required').nullable(),
			otherwise: Yup.number().nullable()
		}),
	conferenceId: Yup.number()
		.when('method', {
			is: (method:number) => method !== null && method === AppointmentMethod.conference,
			then: Yup.number()
				.transform((value) => (isNaN(value) ? undefined : value))
				.required('Conference name is required'),
			otherwise: Yup.number().nullable()
		}),
	location: Yup.string()
		.when('method', {
			is: (method: number) => method !== null && method === AppointmentMethod.faceToFace,
			then: Yup.string()
				.required("Appointment location is required"),
			otherwise: Yup.string().nullable()
		}),
	followUpReasonId: Yup.number()
		.when('isFollowUp', {
			is: (isFollowUp: boolean) => isFollowUp,
			then: Yup.number()
				.transform((value) => (isNaN(value) ? undefined : value))
				.required('Follow up reason required'),
			otherwise: Yup.number().nullable()
		})
});

export const customerValidationSchema = Yup.object({
	name: Yup.string().required('Name is required'),
	sector: Yup.string().required('Sector is required'),
	products: Yup.array().required('Product is required').min(1).nullable(),
	website: Yup.string().required('Website is required'),
	region: Yup.number().required('Region is required').nullable(),
	bookingEngineType: Yup.number()
		.transform((value) => (isNaN(value) ? undefined : value))
		.required("Booking engine type is required"),
	bookingEngineName: Yup.string()
		.when('bookingEngineType', {
			is: (method: number) => method == BookingEngineType.Other,
			then: Yup.string().required('Booking engine name is required'),
			otherwise: Yup.string().nullable()
		})
		.max(100, "Booking engine name can't be longer than 100 characters")
});

export const contactValidationSchema = Yup.object({
	name: Yup.string().required('Name is required'),
	email: Yup.string().required('Email is required'),
	jobTitle: Yup.string().required('Job title is required'),
	customerId: Yup.number()
		.transform((value) => (isNaN(value) ? undefined : value))
		.required('Company is required')
		.nullable(),
});

export const bdmValidationSchema = Yup.object({
	zoomLink: Yup.string().required('Zoom link is required'),
	phoneNumber: Yup.string().required('Phone number is required'),
	sector: Yup.number().required('Sector is required'),
	primaryRegion: Yup.number().required('Primary Region is required')
});

export const contactBookAppointmentValidationSchema = Yup.object({
	contactName: Yup.string().required('Name is required'),
	email: Yup.string().email().required('Email is required'),
	jobTitle: Yup.string().required('Job title is required'),
	company: Yup.string().required('Company name is required')
});

export const conferenceValidationSchema = Yup.object({
	name: Yup.string().required('Name is required')
});

export const rescheduleValidationSchema = Yup.object({
	startDate: Yup.string().required('Date and time is required'),
	duration: Yup.string().required('Enter length of appointment'),
})

export const followUpReasonValidationSchema = Yup.object({
	name: Yup.string().required('Reason is required')
})