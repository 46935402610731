import {Form, Formik} from 'formik';
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {Input} from '../FormFields/Input';
import {FormSelect} from '../FormFields/FormSelect';
import {productOptions, regionOptions, sectorOptions} from '../dropdownOptions';
import {customerDrawer, editCompanyDrawer} from '../State/Drawer';
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {customerValidationSchema} from '../FormikProps/Validation';
import {BookingEngineType, ICustomerFormModel} from '../Types/Customer';
import {exceptionErrorMessageState, validationErrorMessagesState} from '../State/Error';
import {GenericAutoComplete} from "../FormFields/GenericAutoComplete";
import {useState} from "react";

interface CustomerFormProps {
	initialValues: ICustomerFormModel,
	onSubmit: any,
	label: string
}


export const CustomerForm = ({initialValues, onSubmit, label}: CustomerFormProps) => {
	const resetValidationMessages = useResetRecoilState(validationErrorMessagesState);

	const resetExceptionMessages = useResetRecoilState(exceptionErrorMessageState);

	const exceptionMessage = useRecoilValue(exceptionErrorMessageState);

	const setCustomerDrawer = useSetRecoilState(customerDrawer);

	const setEditCustomerDrawer = useSetRecoilState(editCompanyDrawer);

	const [bookingEngineTypeState, setBookingEngineTypeState] = useState<number>(initialValues.bookingEngineType)

	const handleClose = () => {
		setCustomerDrawer(false);

		setEditCustomerDrawer(false);

		resetValidationMessages();

		resetExceptionMessages();
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={customerValidationSchema}
			onSubmit={onSubmit}
			validateOnMount
			enableReinitialize
		>
			{formProps => (
				<>
					<Form>
						<DialogTitle
							id="form-dialog-title">
							{label}
						</DialogTitle>
						<DialogContent>
							<Input
								name={'name'}
								type={'text'}
								label={'Name'}
								validationProperty={'name'}
								formProps={formProps}
								value={initialValues.name}
							/>
							<FormSelect
								name={'sector'}
								type={'text'}
								label={'Sector'}
								options={sectorOptions}
								validationProperty={'sector'}
								formProps={formProps}
								value={initialValues.sector}
							/>
							<GenericAutoComplete
								name={'products'}
								label={'Products'}
								options={productOptions}
								placeholder={'Select Products'}
								formProps={formProps}
								multiple
								validationProperty={'products'}
							/>

							<Input
								name={'website'}
								type={'text'}
								label={'Website'}
								validationProperty={'website'}
								formProps={formProps}
								value={initialValues.website}
							/>

							<FormSelect
								name={'region'}
								label={'Region'}
								options={regionOptions}
								validationProperty={'region'}
							/>

							<FormSelect
								name='bookingEngineType'
								label='Expected Booking Engine Type'
								options={[
									{
										label: 'Unknown', value: BookingEngineType.Unknown
									},
									{
										label: 'In-house', value: BookingEngineType.InHouse
									},
									{
										label: 'Other', value: BookingEngineType.Other
									}
								]}
								validationProperty={'bookingEngineType'}
								value={bookingEngineTypeState}
								handleChange={(e:any) => setBookingEngineTypeState(e)}
							/>

							{(bookingEngineTypeState === BookingEngineType.Other) &&
							<Input
								name={'bookingEngineName'}
								type={'text'}
								label={'Engine Name'}
								validationProperty={'bookingEngineName'}
								formProps={formProps}
								value={initialValues.bookingEngineName}
							/>
							}

						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								color="primary"
							>
								Cancel
							</Button>
							<Button
								color="primary"
								type="submit"
								disabled={!formProps.isValid}
							>
								Submit
							</Button>
						</DialogActions>
						<div className='validation-message'>
							{exceptionMessage}
						</div>
					</Form>
				</>
			)}
		</Formik>
	)
}