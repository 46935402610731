import FormControl from '@mui/material/FormControl';
import {useStyles} from './FormStyles'
import {IAppointmentModel} from '../Types/appointment';
import {Field, FieldProps} from 'formik';
import {useAutoCompleteStyles} from '../theme';
import React from "react";
import {InputLabel} from '@mui/material';
import {SelectInput} from "./SelectInput";



export const FormSelect = ({name, options, label, includeEmptyOption, validationProperty, multiple, value, handleChange}: any) => {
	const classes = {
		...useStyles().classes,
		...useAutoCompleteStyles().classes
	}

	return (
		<div className={classes.formControl}>
			<FormControl variant="standard">
				<InputLabel>{label}</InputLabel>
				<Field>
					{
						(fieldProps: FieldProps<IAppointmentModel>) => {
							return (
								<SelectInput
									name={name}
									options={options}
									label={label}
									includeEmptyOption={includeEmptyOption}
									fieldProps={fieldProps}
									styles={[classes.formField]}
									multiple={multiple}
									value={value}
									handleChange={handleChange}
									validationProperty={validationProperty}
								/>
							)
						}
					}
				</Field>
			</FormControl>
		</div>
    );
}