import DayPicker, {DateUtils} from "react-day-picker";
import {useSetRecoilState} from "recoil";
import {appointmentsQueryEndDateState, appointmentsQueryStartDateState} from "../State/User";
import {useEffect, useState} from "react";
import moment from "moment";

const TimeSlotDatePicker = () => {
	const setStartDate = useSetRecoilState(appointmentsQueryStartDateState)
	const setEndDate = useSetRecoilState(appointmentsQueryEndDateState)
	const [dates, setDates] = useState<any>({
		from: undefined,
		to: undefined
	})

	useEffect(() => {
		const {from, to} = dates
		if (from && to) {
			setStartDate(moment(from).startOf('day'))
			setEndDate(moment(to).endOf('day'))
		}
	}, [dates])

	const handleDayClick = (day: Date) => {
		const range = DateUtils.addDayToRange(day, dates)
		setDates(range)
	}

	const {from, to} = dates

	return (
		<DayPicker
			className="Selectable"
			numberOfMonths={1}
			selectedDays={[from, to]}
			modifiers={{
				start: from,
				end: to
			}}
			onDayClick={handleDayClick}
			disabledDays={[
				{
					daysOfWeek: [0, 6],
				},
				{
					before: moment().toDate()
				}
			]}
		/>
	)
}

export {TimeSlotDatePicker}
