import {atom, selector} from "recoil";
import {conferenceInitialValues} from "../FormikProps/InitialValues";
import {IFollowUpReasonModel} from "../Types/FollowUpReason";

export const followUpReasonsState = atom<IFollowUpReasonModel[]>({
    key: 'followUpReasons',
    default: []
})

export const editFollowUpReasonState = atom<IFollowUpReasonModel>({
    key: 'followUpReasonEditState',
    default: conferenceInitialValues
})

export const followUpReasonOptions = selector({
    key: 'followUpReasonOptions',
    get: ({get}) => {
        const followUpReasons = get(followUpReasonsState);
        return followUpReasons.map((x: any) => {
            return {label: x.name, value: x.id};
        });
    }
})