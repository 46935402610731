import Dialog from '@mui/material/Dialog/Dialog'
import React from 'react'
import {useRecoilValue} from 'recoil';
import {successBookAppointmentDrawer} from '../State/Drawer';
import {createdContactAppointmentState} from '../State/Contact';
import '../index.css'
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import moment from 'moment'
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles()({
	root: {
		margin: 'auto',
		textAlign: 'center'
	},
	border: {
		borderTop: 'solid grey',
		borderBottom: 'solid grey',
		width: '50%',
		margin: 'auto',
		marginBottom: 30,
		borderWidth: 'thin'
	},
	paper: {
		minWidth: '80%'
	},
	h1: {
		fontSize: 50,
		marginBottom: 15,
		paddingTop: 15
	},
	h3: {
		fontSize: 27,
		marginBottom: 15
	},
	body1: {
		marginBottom: 15
	},
	details: {
		marginLeft: 15
	},
	icons: {
		marginBottom: 15,
		marginTop: 15
	}
});

export const SuccessBookAppointmentDialog = () => {

	const { classes } = useStyles();

	const successBookAppointment = useRecoilValue(successBookAppointmentDrawer);

	const createdContactAppointment = useRecoilValue(createdContactAppointmentState);

	const history = useHistory();

	if (!createdContactAppointment) {
		history.push('/')
	}

	return (
		<>
			<Dialog
				open={successBookAppointment}
				className={classes.paper}
				fullWidth={true}
			>
				<div className={classes.root}>
					<Typography
						className={classes.h1}
						variant="h1"
						color="primary"
					>
						Confirmed
					</Typography>
					<Typography
						className={classes.h3}
						variant="h3"
						color="secondary"
					>
						You are scheduled with {createdContactAppointment && createdContactAppointment.bdm}
					</Typography>
					<Typography
						className={classes.body1}
						variant="body1"
						color="textPrimary"
					>
						<p>
							We have sent an email and set up a Google calendar event for you.
						</p>
					</Typography>
				</div>
				<div className={classes.border}>
					<div className={classes.icons}>
						<img className={'icon-big'} src={'/call.png'} alt={'image'}/>
						<span className={classes.details}>
				    {createdContactAppointment && createdContactAppointment.phone}
					</span>
					</div>
					<div className={classes.icons}>
						<img className={'icon-big'} src={'/calendar.png'} alt={'image'}/>
						<span className={classes.details}>
				    {createdContactAppointment && createdContactAppointment.startTime && moment(createdContactAppointment.startTime.toLocaleString()).format('LLL')}
					  </span>
					</div>
				</div>
			</Dialog>
		</>
	)
}