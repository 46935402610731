import {atom} from 'recoil';
import {IValidationErrorObject} from '../Types/Error';

export const validationErrorMessagesState = atom<IValidationErrorObject[]>({
	key: 'validationErrorMessagesState',
	default: []
})

export const exceptionErrorMessageState = atom<string>({
	key: 'exceptionErrorMessageState',
	default: ""
})