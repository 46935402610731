import {
	Drawer
} from '@mui/material';
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {reloadAppointmentState} from '../State/AppointmentState';
import {IAppointmentFormModel} from '../Types/appointment';
import {createAppointmentInitialValues} from '../FormikProps/InitialValues';
import {createAppointment} from '../Services/AppointmentService';
import {appointmentDrawer} from '../State/Drawer';
import {AppointmentForm} from './AppointmentForm';
import {authenticatedUserState} from '../State/Auth';
import {toast} from 'react-toastify';
import {exceptionErrorMessageState, validationErrorMessagesState} from '../State/Error';
import {useErrorMessages} from "../Hooks/useErrorMessages";

export const CreateAppointmentSlider = () => {

	const setReload = useSetRecoilState(reloadAppointmentState);

	const {
		resetAllErrorMessages,
		handleErrorResponse
	} = useErrorMessages();

	const [appointmentDrawerIsOpen, setAppointmentDrawer] = useRecoilState(appointmentDrawer);

	const auth = useRecoilValue(authenticatedUserState);

	const onSubmit = async (values: IAppointmentFormModel) => {
		values.createdBy = auth?.profile.name;

		try {
			await createAppointment(values);

			toast.success('Appointment created');

			setReload((val) => val + 1);

			setAppointmentDrawer(false);

			resetAllErrorMessages();

		} catch (e) {
			handleErrorResponse(e);
			toast.error('Failed to create appointment');
		}
	};

	return (
		<>
			<Drawer
				onClose={() => setAppointmentDrawer(false)}
				open={appointmentDrawerIsOpen}
				anchor="right"
				variant="temporary"
			>
				<AppointmentForm
					initialValues={createAppointmentInitialValues}
					onSubmit={onSubmit}
					label={'Create'}
				/>
			</Drawer>
		</>
	)
}
