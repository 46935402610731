import {FeedbackQuestionSetProps} from "../../Types/AppointmentFeedback";
import {Box, Grid, Typography} from "@mui/material";
import {FeedbackQuestion} from "./FeedbackQuestion";

export const FeedbackQuestionSet = (props: FeedbackQuestionSetProps) => {
    const { questionSet, toggleSelected, meetingNotRelevant } = props

    return (
        <Box paddingY={2} width="100%">
            <Grid container>
                <Grid item>
                    <Typography variant="h5" color="primary">{questionSet.title}</Typography>
                </Grid>
                <Grid item container>
                    {
                        questionSet.questions.map(x => (
                            <FeedbackQuestion
                                key={x.id}
                                question={x}
                                toggleSelected={toggleSelected}
                                disabled={meetingNotRelevant}
                            />)
                        )
                    }
                </Grid>
            </Grid>
        </Box>
    )
}