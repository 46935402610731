import {Drawer} from '@mui/material';
import {useRecoilState, useResetRecoilState, useSetRecoilState} from 'recoil';
import {contactInitialValues} from '../FormikProps/InitialValues';
import {createContact} from '../Services/ClientService';
import {contactsState} from '../State/Contact';
import {contactDrawer} from '../State/Drawer';
import {IAutoCompleteProps} from '../Types/appointment';
import {IContactFormModel} from '../Types/Contact';
import {toast} from 'react-toastify';
import {ContactForm} from './ContactForm';
import {exceptionErrorMessageState, validationErrorMessagesState} from '../State/Error';
import {useErrorMessages} from "../Hooks/useErrorMessages";

export const CreateContactSlider = ({fieldProps}: IAutoCompleteProps) => {

	const [contacts, setContact] = useRecoilState(contactsState);

	const {
		resetAllErrorMessages,
		handleErrorResponse
	} = useErrorMessages();

	const [contactDrawerIsOpen, setContactDrawer] = useRecoilState(contactDrawer);

	const onSubmit = async (values: IContactFormModel) => {

		try {

			const contact = await createContact(values);

			toast.success('Contact successfully created')

			setContact([...contacts, contact.data])

			let currentContactIdsValue = fieldProps.form.getFieldMeta('contactIds')?.value as []

			currentContactIdsValue ?
				fieldProps.form.setFieldValue('contactIds', [...currentContactIdsValue, contact.data.contactId]) :
				fieldProps.form.setFieldValue('contactIds', [contact.data.contactId])

			setContactDrawer(false);

		} catch (e) {
			handleErrorResponse(e);
			toast.error('Contact unsuccessfully created');
		}

	};

	const handleClose = () => {
		setContactDrawer(false);

		resetAllErrorMessages();
	}

	return (
		<>
			<Drawer
				onClose={handleClose}
				open={contactDrawerIsOpen}
				anchor="right"
				variant="temporary"
			>
				<ContactForm
					onSubmit={onSubmit}
					initialValues={contactInitialValues}
					label={'Create Contact'}
				/>
			</Drawer>
		</>
	)
}