import {FormikProps} from 'formik';
import {Region, AppointmentStatus, IAppointmentModel} from './appointment';
import {CustomerProduct} from './Customer';
import React from "react";
import {AutocompleteRenderInputParams} from "@mui/material/Autocomplete";

export interface IAutoCompleteProps<T> {
	name: string,
	label: string,
	placeholder?: string,
	multiple?: boolean
	formProps: FormikProps<T>,
	options: IAutoCompleteOptionsProps[],
	RenderOptions?: React.ElementType,
	ExtraFormSlider?: React.ElementType,
	TextFieldInputProps?: (params: AutocompleteRenderInputParams) => {};
	validationProperty?: string;
	customChangeHandler?: (newValue?: any) => void;
}

export interface IAutoCompleteOptionsProps {
	label: string,
	value: number
}

export interface IAutoCompleteOptionElementProps {
	option: any,
	inputProps: React.HTMLAttributes<HTMLLIElement>
}

export interface IRowProps {
	appointment: IAppointmentModel
}

export interface IPaging {
	count: number,
	page: number,
	pageSize: number,
	filter: string,
	startDate: string,
	endDate: string,
	user: string,
	status: AppointmentStatus,
	region: number,
	product: CustomerProduct
}

export const PAGING_STORAGE_KEY = "paging";