export interface IUserModel {
    userId: number,
    email: string,
    name: string,
    zoomLink: string,
    region: number,
    permissions: number,
    timeZone: string
}

export interface IUserAvailableTimes {
    bdm:string,
    availableTimeStart: string,
    availableTimeEnd: string,
    timezone: string,
    day:number
}

export interface AppointmentSlots {
    key: string;
    slots: AppointmentSlot[];
}

export interface AppointmentSlot {
    start: string;
    durationMinutes: number;
    contact: string;
    clientTimezone:string;
    bdmId: number
}

export enum UserPermissions {
    BDM,
    BDE,
    None,
    CommercialManager,
    Partnerships
}
