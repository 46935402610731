import { atom } from "recoil";

export const appointmentDrawer = atom<boolean>({
    key: "appointmentDrawer",
    default: false
})

export const editAppointmentDrawer = atom<boolean>({
    key: "editAppointmentDrawer",
    default: false
})

export const customerDrawer = atom<boolean>({
    key: "customerDrawer ",
    default: false
})

export const contactDrawer = atom<boolean>({
	key: 'contactDrawer',
	default: false
})

export const conferenceDrawer = atom<boolean>({
	key: 'conferenceDrawer',
	default: false
})

export const followUpReasonDrawer = atom<boolean>({
	key: 'followUpReasonDrawer',
	default: false
})

export const contactBookAppointmentDrawer = atom<boolean>({
	key: 'ContactBookAppointmentDrawer',
	default: false
})

export const successBookAppointmentDrawer = atom<boolean>({
	key: 'SuccessBookAppointmentDrawer',
	default: false
})

export const editCompanyDrawer = atom<boolean>({
	key: 'EditCompanyDrawer',
	default: false
})

export const editContactDrawer = atom<boolean>({
	key: 'EditContactDrawer',
	default: false
})

export const editBdmDrawer = atom<boolean>({
	key: 'EditBDMDrawer',
	default: false
})

export const editConferenceDrawer = atom<boolean>({
	key: 'EditConferenceDrawer',
	default: false
})

export const rescheduleDrawer = atom<boolean>({
	key: "rescheduleDrawer",
	default: false
})

export const editFollowUpReasonDrawer = atom<boolean>({
	key: 'EditFollowUpReasonDrawer',
	default: false
})