import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {exceptionErrorMessageState, validationErrorMessagesState} from "../State/Error";
import {editBdmDrawer} from "../State/Drawer";
import {isAdminState} from "../State/Auth";
import {deleteBdm} from "../Services/BdmService";
import {toast} from "react-toastify";
import {useErrorMessages} from "./useErrorMessages";

export const useBdmForm = (userId:number) => {

    const {
        resetAllErrorMessages,
        handleErrorResponse,
        validationErrorMessage
    } = useErrorMessages();

    const exceptionMessage = useRecoilValue(exceptionErrorMessageState);

    const setEditDrawer = useSetRecoilState(editBdmDrawer);

    const isAdmin = useRecoilValue(isAdminState);

    const handleClose = () => {
        setEditDrawer(false);

        resetAllErrorMessages();
    }

    const handleDelete = async () => {
        try {
            await deleteBdm(userId);

            toast.success('BDM successfully deleted');

            setEditDrawer(false);

            resetAllErrorMessages();

            window.location.reload();

        } catch (e) {
            handleErrorResponse(e);
            toast.error('Failed to delete BDM');
        }
    }

    return [isAdmin, handleDelete, validationErrorMessage, handleClose, exceptionMessage] as const;
};