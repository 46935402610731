import Drawer from '@mui/material/Drawer/Drawer'
import React from 'react'
import {editCompanyDrawer} from '../State/Drawer';
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {CustomerForm} from './CustomerForm';
import {customerEditedState, editCustomerState} from '../State/Customer';
import {ICustomerEditFormModel} from '../Types/Customer';
import {editCustomerById} from '../Services/CustomerService';
import {toast} from 'react-toastify';
import {exceptionErrorMessageState, validationErrorMessagesState} from '../State/Error';
import {useErrorMessages} from "../Hooks/useErrorMessages";

export const EditCustomerSlider = () => {

	const [editCompanyDrawerIsOpen, setEditCustomerDrawer] = useRecoilState(editCompanyDrawer);

	const [edit, setEdit] = useRecoilState(customerEditedState);

	const {
		resetAllErrorMessages,
		handleErrorResponse
	} = useErrorMessages();

	const customerValue = useRecoilValue(editCustomerState);

	const onSubmit = async (values: ICustomerEditFormModel) => {
		try {

			await editCustomerById(values);

			toast.success('Company successfully edited');

			setEdit(edit + 1);

			setEditCustomerDrawer(false);

			resetAllErrorMessages();

		} catch (e) {
			handleErrorResponse(e);
			toast.error('Customer update failed');
		}
	}

	const handleClose = () => {
		setEditCustomerDrawer(false);

		resetAllErrorMessages()
	}

	return (
		<Drawer
			onClose={handleClose}
			open={editCompanyDrawerIsOpen}
			anchor="right"
			variant="temporary"
		>
			<CustomerForm
				onSubmit={onSubmit}
				initialValues={customerValue}
				label={'Edit Company'}
			/>
		</Drawer>
	)
}