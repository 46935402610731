import axios from "axios";
import {rootUrl} from "./AppointmentService";
import {IConferenceModel} from "../Types/Conference";

export const getConferences = async () => axios.get<IConferenceModel[]>(`${rootUrl}/conferences`);

export const getConferenceById = async (conferenceId: number) => await axios.get(`${rootUrl}/conference/${conferenceId}`);

export const createConference = async (conference: IConferenceModel) => await axios.post(`${rootUrl}/conference`, conference)

export const editConference = async (conference: IConferenceModel) => await axios.put(`${rootUrl}/conference/${conference.id}`, conference)
