import {User} from 'oidc-client';
import {atom, selector} from 'recoil';

export const authenticatedUserState = atom<User | null>({
	key: 'authenticatedUserState',
	default: null
});

export const isUserAuthenticatedState = selector<boolean>({
	key: 'isAuthenticatedState',
	get: ({get}) => {
		const user = get(authenticatedUserState);
		return user !== null;
	}
});

const userRolesState = selector<string>({
	key: 'userRolesState',
	get: ({get}) => {
		const user = get(authenticatedUserState)

		if (user !== null && (user as User) !== null) {
			const cast: User = (user as User);
			return cast.profile?.Roles;
		}

		return ''
	}
})

export const isAdminState = selector<boolean>({
	key: 'isAdminState',
	get: ({get}) => {
		const roles = get(userRolesState)
		return roles.indexOf('AppointmentTrackerAdmin') > -1
	}
})
