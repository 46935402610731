import {Route, Switch} from 'react-router-dom'
import {Login} from '../Auth/Login'
import {HomePage} from '../Views/HomePage'
import {AuthenticatedRoute} from '../Auth/AuthenticatedRoute'
import {SignInRedirect} from '../Auth/SignInRedirect'
import {LandingPage} from '../Views/LandingPage'
import {CreateAppointment} from '../Views/CreateAppointment'
import {Setup} from '../Auth/Setup'
import {Unauthorised} from '../Auth/Unauthorised'
import {BookAppointmentSuccess} from '../Views/BookAppointmentSuccess'
import AppointmentFeedback from '../Views/AppointmentFeedback'

export const Routes = () => {
	return (
		<Switch>
			<Route exact path="/redirect" component={SignInRedirect}/>
			<Route exact path="/login" component={Login}/>
			<Route exact path="/create/:productId" component={CreateAppointment}/>
			<Route exact path="/create" component={CreateAppointment}/>
			<Route exact path="/setup" component={Setup}/>
			<Route exact path="/unauthorized" component={Unauthorised}/>
			<Route exact path="/success" component={BookAppointmentSuccess}/>

			<AuthenticatedRoute exact path="/dashboard">
				<HomePage/>
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/appointment/:appointmentId/feedback">
				<AppointmentFeedback/>
			</AuthenticatedRoute>
			<Route path="/:productId" component={LandingPage}/>
			<Route path="/" component={LandingPage}/>
		</Switch>
	)
}
