import {AppointmentStatus, IAppointmentModel} from '../Types/appointment'
import {useCallback, useEffect, useState} from 'react'
import {useRecoilValue} from 'recoil'
import {isAdminState} from '../State/Auth'
import {loggedInUserState} from '../State/User'
import {UserPermissions} from '../Types/User'
import {appointmentPagingState} from '../State/AppointmentState'

export const useHeaderPermissions = () => {
	const [canViewStatus, setCanViewStatus] = useState<boolean>(false)
	const [canViewScore, setCanViewScore] = useState<boolean>(false)
	const [canViewValidation, setCanViewValidation] = useState<boolean>(false)
	const isAdmin = useRecoilValue(isAdminState)
	const user = useRecoilValue(loggedInUserState)
	const filters = useRecoilValue(appointmentPagingState)

	const setState = useCallback(() => {
		const isBdm = user.permissions === UserPermissions.BDM || user.permissions === UserPermissions.Partnerships

		setCanViewStatus(() => {
			return isAdmin || isBdm
		})

		setCanViewScore(() => {
			return (isAdmin || isBdm) && filters.status === AppointmentStatus.Complete
		})

		setCanViewValidation(isAdmin)
	}, [isAdmin, user, filters])

	useEffect(setState, [setState])

	return { canViewStatus, canViewScore, canViewValidation }
}

export const useRowPermissions = (appointment: IAppointmentModel) => {
	const [canEditStatus, setCanEditStatus] = useState<boolean>(false)
	const [canEditScore, setCanEditScore] = useState<boolean>(false)
	const { canViewStatus, canViewScore } = useHeaderPermissions()
	const user = useRecoilValue(loggedInUserState)
	const isAdmin = useRecoilValue(isAdminState)

	const setState = useCallback(() => {
		const userInContacts = appointment.users.find(x => x.email == user.email) !== undefined

		setCanEditStatus(() => {
			return canViewStatus && userInContacts || isAdmin
		})

		setCanEditScore(() => {
			return isAdmin || (canViewScore && userInContacts)
		})
	}, [appointment, canViewScore, canViewStatus])

	useEffect(setState, [setState])

	return { canEditStatus, canViewStatus, canViewScore, canEditScore }
}
