import {atom, selector} from 'recoil';
import {IContactModel} from '../Types/Contact';
import _ from 'lodash';
import {selectedCustomerState} from "./Customer";

export const contactsState = atom<IContactModel[]>({
    key: 'contacts',
    default: []
})

export const contactOptions = selector({
    key: 'contactOptions',
    get: ({get}) => {
        let contacts = get(contactsState);
        const selectedCustomer = get(selectedCustomerState);

        if (selectedCustomer !== null) {
            contacts = contacts.filter(contact => contact.customerId === selectedCustomer);
        }

        const groupedContacts = _.groupBy(contacts, 'name');
        let formattedContactOptions: { label: string, value: number }[] = [];
        _.forEach(groupedContacts, (groupedContact) => {
            const duplicateContactName = groupedContact.length > 1;
            // Will run once unless there's a duplicate lead name
            _.forEach(groupedContact, (contact) => {
                formattedContactOptions.push({
                    label: duplicateContactName ? `${contact.name} - ${contact.email}` : contact.name,
                    value: contact.contactId
                })
            })
        });
        return formattedContactOptions;
    }
})

export const contactEditedState = atom<number>({
    key: 'contactEditedState',
    default: 0
})

export const formContactAppointmentState = atom<any>({
    key: 'formContactAppointment',
    default: ''
})

export const editContactState = atom<any>({
    key: 'contactState',
    default: null
})

export const createdContactAppointmentState = atom<any>({
    key: 'createdContactAppointment',
    default: null
})