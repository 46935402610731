import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {Form, Formik, FormikProps} from 'formik';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {Input} from '../FormFields/Input';
import {contactBookAppointmentInitialValues} from '../FormikProps/InitialValues';
import {contactBookAppointmentValidationSchema} from '../FormikProps/Validation';
import {createContactBooking} from '../Services/ClientService';
import {IContactBookAppointmentFormModel} from '../Types/Contact';
import {toast} from 'react-toastify';
import {contactBookAppointmentDrawer, successBookAppointmentDrawer} from '../State/Drawer';
import {formContactAppointmentState, createdContactAppointmentState} from '../State/Contact';
import React, {createRef, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {appointmentQueryRegion, appointmentQuerySector, appointmentsQueryParamState} from '../State/User';
import useIntegrationAppointment from "../Hooks/useIntegrationAppointment";
import ReCAPTCHA from "react-google-recaptcha";
import {ValidationMessage} from "./ValidationMessage";
import {sectorOptions} from "../dropdownOptions";
import {FormSelect} from "../FormFields/FormSelect";

export const CreateContactBookAppointmentSlider = () => {

	const [contactBookAppointmentDrawerIsOpen, setContactBookAppointmentDrawer] = useRecoilState(contactBookAppointmentDrawer);

	const [disableButton, setDisableButton] = useState<boolean>(false)

	const productId = useRecoilValue(appointmentsQueryParamState);

	const setSuccessBookAppointmentDrawer = useSetRecoilState(successBookAppointmentDrawer);

	const setCreatedContactAppointment = useSetRecoilState(createdContactAppointmentState);

	const sector = useRecoilValue(appointmentQuerySector);

	const history = useHistory();

	const formValues = useRecoilValue(formContactAppointmentState);

	const {member} = useIntegrationAppointment()

	const captchaRef = createRef<ReCAPTCHA>();

	const formRef = useRef<FormikProps<any>>(null);

	const onSubmit = async (values: IContactBookAppointmentFormModel) => {

		try {

			const token = await captchaRef?.current?.executeAsync();

			if (!token) {
				formRef?.current?.setFieldError("recaptchaToken", "Failed to get captcha token");
				return;
			}

			setDisableButton(true);

			values.startTime = formValues.start;

			values.bdmName = formValues.contact;

			values.bdmId = formValues.bdmId;

			values.contactTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

			values.productId = productId;

			values.recaptchaToken = token;

			const contact = await createContactBooking(values);

			toast.success('Appointment created successfully');

			setCreatedContactAppointment(contact.data);

			setContactBookAppointmentDrawer(false);

			history.push('/success');

			setSuccessBookAppointmentDrawer(true);

		}
		 catch (e) {
			setDisableButton(false);

			toast.error('Unable to create contact')
		}

	};

	return (
		<>
			<Dialog
				onClose={() => setContactBookAppointmentDrawer(false)}
				open={contactBookAppointmentDrawerIsOpen}
			>
				<Formik
					initialValues={member || contactBookAppointmentInitialValues}
					validationSchema={contactBookAppointmentValidationSchema}
					onSubmit={onSubmit}
					validateOnMount
					innerRef={formRef}
				>
					{props => (
						<Form>
							<DialogTitle
								id="form-dialog-title"
							>
								Add your details
							</DialogTitle>
							<DialogContent>
								<DialogContentText>
								</DialogContentText>

								<Input
									name={'contactName'}
									type={'text'}
									label={'Name'}
									validationProperty={'contactName'}
									formProps={props}
								/>

								<Input
									name={'email'}
									type={'text'}
									label={'Email'}
									validationProperty={'email'}
									formProps={props}
								/>
								<Input
									name={'phone'}
									type={'text'}
									label={'Phone number'}
									formProps={props}
								/>
								<Input
									name={'jobTitle'}
									type={'text'}
									label={'Job title'}
									validationProperty={'jobTitle'}
									formProps={props}
								/>
								<Input
									name={'company'}
									type={'text'}
									label={'Company'}
									validationProperty={'company'}
									formProps={props}
								/>
								<FormSelect
									name={'sector'}
									type={'text'}
									label={'Sector'}
									options={sectorOptions}
									validationProperty={'Sector'}
									value={sector}
								/>
								<ReCAPTCHA
									sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
									ref={captchaRef}
									size="invisible"
								/>
								<ValidationMessage propertyName={"recaptchaToken"}  formProps={props}/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => setContactBookAppointmentDrawer(false)}
									color="primary"
								>
									Cancel
								</Button>
								<Button
									color="primary"
									type="submit"
									disabled={disableButton || !props.isValid}
								>
									Submit
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</>
	)
}