import '../css/App.css'

export const Setup = () => {
	return (
		<div className={'text'}>
			<h1>
				You are not yet setup on the appointment tracker. Please contact <a href={'mailto:technology@protectgroup.com'}>technology@protectgroup.com</a> to get you setup!
			</h1>
		</div>
	)
};