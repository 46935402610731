import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {Form, Formik} from 'formik'
import {DatePicker} from '../FormFields/DatePicker'
import {FormSelect} from '../FormFields/FormSelect'
import {
    Duration, IRescheduleModel
} from '../Types/appointment'
import {appointmentValidationSchema, rescheduleValidationSchema} from '../FormikProps/Validation';
import React, {useState} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {appointmentEditedState, rescheduleAppointmentState} from "../State/AppointmentState";
import {useErrorMessages} from "../Hooks/useErrorMessages";
import {rescheduleAppointment} from "../Services/AppointmentService";
import {toast} from "react-toastify";
import {rescheduleDrawer} from "../State/Drawer";

export const RescheduleForm = () => {

    const initialValues = useRecoilValue(rescheduleAppointmentState);

    const [edit, setEdit] = useRecoilState(appointmentEditedState);

    const setRescheduleDrawer = useSetRecoilState(rescheduleDrawer);

    const [dateValue, setDateValue] = useState<string>(initialValues.startDate);

    const {
        resetAllErrorMessages,
        handleErrorResponse,
        exceptionErrorMessage
    } = useErrorMessages();

    const onSubmit = async (values: IRescheduleModel) => {

        try {

            await rescheduleAppointment(values);

            toast.success('Appointment successfully updated');

            setEdit(edit + 1);

            setRescheduleDrawer(false);

            resetAllErrorMessages();

        } catch (e) {
            handleErrorResponse(e);
            toast.error('Appointment update failed');
        }
    }

    const handleClose = () => {
        setRescheduleDrawer(false)

        resetAllErrorMessages();
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={rescheduleValidationSchema}
            onSubmit={onSubmit}
            validateOnMount
            enableReinitialize
        >
            {props => {
                return <Form>
                    <DialogTitle
                        id="form-dialog-title">
                        Reschedule Appointment
                    </DialogTitle>

                    <DialogContent>

                        <DatePicker
                            name={'appointmentDateTime'}
                            label={'Date And Time'}
                            type={'datetime-local'}
                            value={dateValue}
                            onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                                props.handleChange(e);
                                setDateValue(e.currentTarget.value);
                            }}
                            onBlur={props.handleBlur}
                        />

                        <FormSelect
                            name={'duration'}
                            label={'Duration'}
                            options={[
                                {
                                    label: '15 Minutes', value: Duration.fifteen
                                },
                                {
                                    label: '30 Minutes', value: Duration.thirty
                                },
                                {
                                    label: '45 Minutes', value: Duration.fortyFive
                                },
                                {
                                    label: '1 hour', value: Duration.hour
                                },
                            ]}
                            validationProperty={'Duration'}
                        />

                        <div className='validation-message'>
                            {exceptionErrorMessage?.length !== 0 && exceptionErrorMessage}
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            disabled={!props.isValid}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Form>
            }}
        </Formik>
    )
}