import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {MeetingNotRelevantReasons, MeetingNotRelevantSelectProps} from "../../Types/AppointmentFeedback";
import React from "react";

export const MeetingNotRelevantSelect = ({meetingNotRelevantReason, handleMeetingNotRelevantReasonChange}: MeetingNotRelevantSelectProps) => {
    return (
        <div>
            <FormControl variant="standard" fullWidth={true}>
                <InputLabel id="meeting-not-relevant-reason">Reason</InputLabel>
                <Select
                    variant="standard"
                    labelId="meeting-not-relevant-reason"
                    id="meeting-not-relevant-reason"
                    label="meetingNotRelevantReason"
                    value={meetingNotRelevantReason}
                    onChange={(changeEvent)=>{handleMeetingNotRelevantReasonChange(changeEvent.target.value as number)}}>
                    {Object
                        .keys(MeetingNotRelevantReasons)
                        .map((reason, i) => {
                            return <MenuItem key={`reason-${i}`} value={reason}>{MeetingNotRelevantReasons[reason as keyof typeof MeetingNotRelevantReasons]}</MenuItem>
                        })}
                </Select>
            </FormControl>
        </div>
    );
}