import {Button, Dialog, DialogActions, DialogTitle} from '@mui/material'
import {toast} from 'react-toastify';
import {useRecoilState, useRecoilValue} from 'recoil';
import {deleteAppointment} from '../Services/AppointmentService';
import {deleteAppointmentState, toDeleteIdState} from '../State/AppointmentState';
import {deleteDialog} from '../State/Dialog';

export const DeleteDialog = () => {

	const [dialog, setDeleteDialog] = useRecoilState(deleteDialog);
	const [isDeleted, setDeleted] = useRecoilState(deleteAppointmentState);

	const appointmentId = useRecoilValue(toDeleteIdState)

	const handleDelete = async () => {
		try {
			const deletedAppointment = await deleteAppointment(appointmentId);

			if (deletedAppointment.status == 200) {
				toast.success('Appointment successfully deleted');
			}

			setDeleted(isDeleted + 1);

			setDeleteDialog(false);

		} catch (e) {
			toast.error('Appointment unsuccessfully deleted');
		}
	}

	return (
		<>
			<Dialog
				open={dialog}
				onClose={() => setDeleteDialog(false)}
			>
				<DialogTitle>Are you sure you want to delete this appointment?</DialogTitle>
				<DialogActions>
					<Button color="primary" onClick={() => setDeleteDialog(false)}>
						Cancel
					</Button>
					<Button
						color="primary"
						onClick={() => handleDelete()}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}