import { createTheme } from "@mui/material";
import {red} from "@mui/material/colors";
import { makeStyles } from 'tss-react/mui';
const theme = createTheme({
    palette: {
        primary: {
            main: '#495c67',
            light: '#758995',
            dark: '#20333d',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#8b9da5',
            light: '#bbced6',
            dark: '#5e6f76',
            contrastText: '#ffffff'
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: 'Raleway, Arial'
    }
});

export const useAutoCompleteStyles = makeStyles()(() => ({
    formField: {
        width: 250
    }
}));

export const useLayoutStyles = makeStyles()(theme => ({
    p: {
        padding: theme.spacing(2)
    },
    mt: {
        marginTop: theme.spacing(4)
    },
    mb: {
        marginBottom: theme.spacing(4)
    },
    pr: {
        paddingRight: theme.spacing(2)
    },
    pl: {
        paddingLeft: theme.spacing(2)
    },
    px: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    pt: {
        paddingTop: theme.spacing(2)
    },
    pb: {
        paddingBottom: theme.spacing(2)
    },
    pb4: {
        paddingBottom: theme.spacing(4)
    },
    py: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    my: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    mr1: {
        marginRight: theme.spacing(1)
    },
    mr2: {
        marginRight: theme.spacing(2)
    },
    flexFill: {
        flexGrow: 1
    }
}));

export default theme;