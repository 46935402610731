import React, {HTMLAttributes, useState} from 'react';
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import '../index.css'

const useOptionStyles = makeStyles()(() => ({
	root: {
		width: '100%'
	},
	deleteIcon: {
		marginRight: "40px"
	}
}));

interface AutoCompleteOptionProps {
	option: any;
	onEdit?: (value: any) => {}
	onDelete?: ()  => {}
	inputProps?: HTMLAttributes<HTMLLIElement>
}

export const AutoCompleteOption = (props: AutoCompleteOptionProps) => {
	const { classes } = useOptionStyles()
	const {option: {label, value}, onEdit, onDelete, inputProps} = props
	const [showAction, setShowAction] = useState(false)

	const toggleShowAction = (value: boolean) => () => onEdit && setShowAction(value)

	const handleEdit = () => onEdit && onEdit(value);

	return (
		<div className={classes.root} onMouseEnter={toggleShowAction(true)} onMouseLeave={toggleShowAction(false)}>
			<ListItem disableGutters {...inputProps}>
				<ListItemText
					primary={label}
				/>
				{
					(onEdit) && (
						<ListItemSecondaryAction>
							{
								(showAction) && (
									<IconButton onClick={handleEdit} size="large">
										<img className={'icon-small'} src={'/pencil.png'}/>
									</IconButton>
								)
							}
						</ListItemSecondaryAction>
					)
				}
			</ListItem>
		</div>
    );
}