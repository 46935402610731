import Drawer from '@mui/material/Drawer/Drawer'
import React from 'react'
import {ConferenceForm} from "./ConferenceForm";
import {useEditConferenceSlider} from "../Hooks/useEditConferenceSlider";

export const EditConferenceSlider = () => {
    const {
        handleClose,
        editConferenceDrawerIsOpen,
        onSubmit,
        conferenceValue
    } = useEditConferenceSlider();

    return (
        <Drawer
            onClose={handleClose}
            open={editConferenceDrawerIsOpen}
            anchor="right"
            variant="temporary"
        >
            <ConferenceForm
                onSubmit={onSubmit}
                initialValues={conferenceValue}
                label={'Edit Conference'}
            />
        </Drawer>
    )
}