import {useRecoilState, useRecoilValue} from "recoil";
import {editConferenceDrawer} from "../State/Drawer";
import {editConferenceState} from "../State/Conference";
import {IConferenceModel} from "../Types/Conference";
import {editConference} from "../Services/ConferenceService";
import {toast} from "react-toastify";
import {useErrorMessages} from "./useErrorMessages";

export const useEditConferenceSlider = () => {

    const {
        resetAllErrorMessages,
        handleErrorResponse
    } = useErrorMessages();

    const [editConferenceDrawerIsOpen, setEditConferenceDrawer] = useRecoilState(editConferenceDrawer);

    const conferenceValue = useRecoilValue(editConferenceState);

    const onSubmit = async (values: IConferenceModel) => {
        try {

            await editConference(values);

            toast.success('Contact successfully edited');

            setEditConferenceDrawer(false);

            resetAllErrorMessages()

        } catch (e) {
            handleErrorResponse(e);
            toast.error('Conference update failed');
        }
    }

    const handleClose = () => {
        setEditConferenceDrawer(false);

        resetAllErrorMessages();
    }

    return {
        handleClose,
        editConferenceDrawerIsOpen,
        onSubmit,
        conferenceValue
    }
}