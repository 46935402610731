import {Form, Formik} from 'formik';
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {Input} from '../FormFields/Input';
import {bdmValidationSchema} from '../FormikProps/Validation';
import {IBdmFormModel} from "../Types/Bdm";
import { makeStyles } from 'tss-react/mui';
import DeleteIcon from "@mui/icons-material/Delete";
import {useBdmForm} from "../Hooks/useBdmForm";
import {regionOptions, sectorOptions} from "../dropdownOptions";
import {FormSelect} from "../FormFields/FormSelect";

interface BdmFormProps {
    initialValues: IBdmFormModel,
    onSubmit: any,
    label: string
}

const useStyles = makeStyles()({
    deleteButton: {
        backgroundColor: "red",
        float: "right",
        color: "white",
        "&:hover": {
            backgroundColor: "#eb3626",
            color: "white",
            boxShadow: "rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px"
        }
    },
    formTitle: {
        marginRight: "10px"
    }
});

export const BdmForm = ({initialValues, onSubmit, label}: BdmFormProps) => {
    const [isAdmin, handleDelete, validationErrorMessages, handleClose, exceptionMessage] = useBdmForm(initialValues.userId);

    const { classes } = useStyles();
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={bdmValidationSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {props => (
                <Form>
                    <DialogTitle
                        id="form-dialog-title">
                        <span className={classes.formTitle}>{label}</span>
                        {isAdmin &&
                        <Button
                            variant="contained"
                            className={classes.deleteButton}
                            startIcon={<DeleteIcon />}
                            onClick={handleDelete}
                        >
                            Delete BDM
                        </Button>
                        }
                    </DialogTitle>
                    <DialogContent>
                        <Input
                            name={'zoomLink'}
                            type={'text'}
                            label={'Zoom Link'}
                            validationProperty={'zoomLink'}
                            formProps={props}
                            value={initialValues.zoomLink}
                        />
                        <Input
                            name={'phoneNumber'}
                            type={'text'}
                            label={'Phone Number'}
                            validationProperty={'phoneNumber'}
                            formProps={props}
                            value={initialValues.phone}
                        />
                        <FormSelect
                            name={'sector'}
                            type={'text'}
                            label={'Sector'}
                            options={sectorOptions}
                            formProps={props}
                            validationProperty={'sector'}
                        />
                        <FormSelect
                            name={'primaryRegion'}
                            label={'Timezone Region'}
                            options={regionOptions}
                            validationProperty={'regionValue'}
                            formProps={props}
                        />
                        <FormSelect
                            name={'supportedRegions'}
                            label={'Regions Covered by BDM'}
                            options={regionOptions}
                            validationProperty={'supportedRegions'}
                            multiple={true}
                            formProps={props}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            disabled={!props.isValid}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                    <div className='validation-message'>
                        {exceptionMessage}
                    </div>
                </Form>
            )}
        </Formik>
    )
}