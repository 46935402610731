import Grid from '@mui/material/Grid';
import React, {useEffect} from 'react';
import 'react-day-picker/lib/style.css';
import {InputLabel, Typography} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {useLayoutStyles} from '../theme';
import {AvailableTimeSlots} from '../Components/AvailableTimeSlots';
import {TimeSlotDatePicker} from '../Components/TimeSlotDatePicker';
import {CreateContactBookAppointmentSlider} from '../Components/ContactBookAppointmentSlider';
import {appointmentQueryRegion, appointmentQuerySector, appointmentsQueryParamState, userState} from '../State/User';
import {useSetRecoilState} from 'recoil';
import {RegionsDropDown} from "../Components/RegionsDropDown";
import {Region} from "../Types/appointment";
import {ProductsDropDown} from "../Components/ProductsDropDown";
import {CustomerProduct} from "../Types/Customer";
import {SectorsDropDown} from "../Components/SectorsDropDown";

const useStyles = makeStyles()(() => ({
	root: {
		background: 'url(/PF_Loop_still.png) top right no-repeat',
		backgroundAttachment: 'fixed',
		minHeight: '100vh',
	}
}));

export const CreateAppointment = (props: any) => {
	const classes = {
		...useLayoutStyles().classes,
		...useStyles().classes
	};

	const {productId} = props.match.params;

	const setProtectProductId = useSetRecoilState(appointmentsQueryParamState);
	const setSector = useSetRecoilState(appointmentQuerySector)
	const setRegion = useSetRecoilState(appointmentQueryRegion);

	useEffect(() => {
		if (productId) {
			setProtectProductId(productId);
		}
	}, [])

	const handleProductChange = (e: any) => {
		setProtectProductId(Number(e.value));
	}

	const handleSectorChange = (e: any) => {
		setSector(Number(e.value));
	}

	const handleRegionChange = (e: any) => {
		setRegion(Number(e.value));
	}

	return (
        <div>
					<Grid
					className={classes.root}
					container
					justifyContent="center">
					<Grid item container xs={12} className={classes.p} justifyContent="space-between">
						<Typography variant="h3" color="primary" className={classes.pb}>
							Select your time from the available slots below
						</Typography>
						<Typography variant="h5" color="primary">
							Tell us a bit about your business and select a date, so we can provide you with the availability of the business development specialist that suits you best.
						</Typography>
						<Grid item container justifyContent={'center'}>
							<Grid item xs={4} >
								<Grid item marginY={2}>
									<Typography variant="h6" color="primary" marginBottom={1}>
										Sector
									</Typography>
									<SectorsDropDown handleChange={handleSectorChange} />
								</Grid>

								<Grid item marginBottom={2}>
									<Typography variant="h6" color="primary" marginBottom={1}>
										Region
									</Typography>
									<RegionsDropDown handleChange={handleRegionChange} defaultValue={Region["North America"]} />
								</Grid>

								<Grid item>
									<Typography variant="h6" color="primary" marginBottom={1}>
										Product
									</Typography>
									<ProductsDropDown handleChange={handleProductChange} defaultValue={productId ?? CustomerProduct.refundProtect}/>
								</Grid>

							</Grid>
							<Grid item xs={4}>
								<TimeSlotDatePicker/>
							</Grid>
						</Grid>

					</Grid>
					<Grid item xs={12} lg={10}>
						<AvailableTimeSlots/>
					</Grid>
				</Grid>
				<CreateContactBookAppointmentSlider
				/>
		</div>
    );
}
