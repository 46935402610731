import {FieldProps} from 'formik';
import {IContactModel} from './Contact';
import {ICustomerModel} from './Customer';
import {IUserModel} from './User';
import {Moment} from "moment";

export interface IAppointmentFormModel {
	userIds: number[],
	appointmentDateTime: string,
	method: AppointmentMethod | null,
	source: AppointmentSource | null
	duration: string,
	description: string,
	customerId: number,
	contactIds: number[],
	createdBy?: string,
	methodId: number | null,
	conferenceId: number | null,
	sendToContact:boolean,
	linkToExistingEvent: boolean,
	googleEventId: string,
	location: string | null,
	followUpReasonId: number | null
}

export interface IAppointmentEditFormModel extends IAppointmentFormModel {
	appointmentId: number
}

export interface IAppointmentFormEditModel {
	appointmentId: number,
	users: IUserModel[],
	appointmentDateTime: string,
	method: string,
	source: AppointmentSource,
	description: string,
	duration: Duration,
	customerId: number,
	status: AppointmentStatus,
	contacts: IContactModel[],
	methodId: number,
	conferenceName: string,
	sendToContact:boolean,
	linkToExistingEvent: boolean,
	googleEventId: string,
	location: string,
	followUpReasonId: number | null
}

export interface IAppointmentModel {
	appointmentId: number,
	users: IUserModel[],
	appointmentDateTime: string,
	method: string,
	source: AppointmentSource,
	description: string,
	duration: Duration,
	customerId: number,
	status: AppointmentStatus,
	contacts: IContactModel[],
	customer: ICustomerModel,
	methodId: number,
	createdBy: string,
	createdDate: string,
	userRegion: {},
	sendToContact: boolean,
	validationStatus: ValidationStatus,
	score: number,
	conferenceName: string,
	meetingNotRelevant: boolean,
	meetingNotRelevantReason?: number,
	linkToExistingEvent: boolean,
	googleEventId: string,
	mrrValue: number,
	duplicateMeeting: boolean,
	location: string,
	followUpReasonId: number | null
}

export interface IRescheduleModel {
	appointmentId: number,
	startDate: string,
	duration: number
}

export interface IFollowUpMeetingCheckModel {
	appointmentDateTime: string,
	customerId: number,
	contactIds: number[]
}

export interface IAppointmentProps {
	isDialogOpened: boolean
	handleCloseDialog: (isOpen: boolean) => void
}

export interface IAutoCompleteProps {
	fieldProps: FieldProps<IAppointmentFormModel>
}

export interface IBdmAppointment {
	eventId: string,
	timeStart: Moment,
	timeEnd: Moment,
	title: string
}

export interface IBdmAppointmentOptions {
	label: string,
	value: string
}

export enum Duration {
	fifteen = 15,
	thirty = 30,
	fortyFive = 45,
	hour = 60
}

export enum AppointmentStatus {
	All = 0,
	Pending = 1,
	Complete = 2,
	Cancelled = 3
}

export enum ValidationStatus {
	Unvalidated = 1,
	Valid = 2,
	Invalid = 3
}

export enum AppointmentMethod {
	email = 0,
	phone = 1,
	zoom = 2,
	conference = 3,
	faceToFace = 4
}

export enum AppointmentSource {
	linkedin,
	zoomInfo,
	other,
	phone
}

export const Region =  {
	"All": -1,
	"North Asia": 6,
	"South Asia": 7,
	"Brazil": 13,
	"Central America": 3,
	"China": 5,
	"Europe": 10,
	"India": 12,
	"Middle East": 15,
	"North America": 14,
	"Oceania": 8,
	"South America": 4,
	"Africa": 16
}

export const AppointmentMethodLabel: Record<number, string> = {
	[AppointmentMethod.phone]: 'Phone Number',
	[AppointmentMethod.zoom]: 'Zoom Link',
	[AppointmentMethod.conference]: 'Conference Name',
	[AppointmentMethod.faceToFace]: 'Location'
}
