import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';
import React, {useEffect} from 'react'
import {useRecoilState} from 'recoil'
import {appointmentPagingState} from '../State/AppointmentState'
import '../css/Table.css'
import {EditAppointmentSlider} from './EditAppointmentSlider'
import {DeleteDialog} from './DeleteDialog'
import {IAppointmentModel} from '../Types/appointment'
import {Rows} from './Rows'
import useAppointmentsData from '../Hooks/useAppointmentData'
import {EditCustomerSlider} from './EditCustomerSlider'
import {EditContactSlider} from './EditContactSlider'
import {DownloadCsvFile} from './DownloadCsvFile'
import {useHeaderPermissions} from '../Hooks/rows'
import {EditBdmSlider} from "./EditBdmSlider";
import {PAGING_STORAGE_KEY} from "../Types/input";
import {EditConferenceSlider} from "./EditConferenceSlider";
import {RescheduleSlider} from "./RescheduleSlider";

const StyledTableCell = withStyles(TableCell, (theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
		fontFamily: 'Helvetica'
	},
}));


const useStyles = makeStyles()({
	tableRow: {
		height: '25%'
	},
	tableHeader: {},
	container: {
		minHeight: 1400,
	},
	table: {},
	pagination: {},
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	appointmentMessage: {
		marginTop: 30,
		display: 'block',
		verticalAlign: 'middle',
		marginLeft: 'auto',
		marginRight: 'auto',
		fontSize: '25px'
	}
});

export const AppointmentList = () => {
	const { classes } = useStyles();
	const {appointments, totalCount} = useAppointmentsData();
	const [paging, setPaging] = useRecoilState(appointmentPagingState)
	const { canViewValidation, canViewScore } = useHeaderPermissions()

	const handleChangePage = async (e: any, pageNumber: number) => {
		setPaging({...paging, page: pageNumber})
	}

	const handleChangeRowsPerPage = async (e: any) => {
		setPaging({...paging, pageSize: e.target.value, page: 0})
	}

	return <>
        {(appointments) && (
            <TableContainer
                component={Paper}
            >
                <Table
                    aria-label="custom pagination table collapsible table"
                    stickyHeader={true}
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>Appointment Date/Time</StyledTableCell>
                            <StyledTableCell>Company</StyledTableCell>
                            <StyledTableCell>BDMs</StyledTableCell>
                            <StyledTableCell>Booked Date/Time</StyledTableCell>
                            <StyledTableCell>Booked By</StyledTableCell>
                            <StyledTableCell>Region</StyledTableCell>
                            <StyledTableCell>Duration</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            {
                                canViewValidation && <StyledTableCell align="center">Valid</StyledTableCell>
                            }
                            {
                                canViewScore && <StyledTableCell align="center">Score</StyledTableCell>
                            }
                            {
                                canViewScore && <StyledTableCell align="center">MRR</StyledTableCell>
                            }
                            <StyledTableCell><DownloadCsvFile/></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        className={classes.container}
                    >
                        {appointments.map((x: IAppointmentModel) => {
                            return (
                                <React.Fragment key={x.appointmentId}>
                                    <Rows appointment={x}/>
                                </React.Fragment>
                            )
                        })}
                        <DeleteDialog/>
                        <EditAppointmentSlider/>
                        <EditCustomerSlider/>
                        <EditContactSlider/>
                        <EditBdmSlider />
												<EditConferenceSlider />
												<RescheduleSlider />
                    </TableBody>
                    <TableFooter className={classes.pagination}>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 25, {label: 'All', value: -1}]}
                                colSpan={3}
                                count={totalCount}
                                rowsPerPage={paging.pageSize}
                                page={paging.page}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )}

        {(totalCount === 0) && (
            <div className={classes.appointmentMessage}>There are no appointments that match the filter criteria selected</div>
        )}
    </>;
}
