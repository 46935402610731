import {Grid} from '@mui/material';
import {useLayoutStyles} from '../../../theme';
import {layoutStyle} from '../Styles/landingPageLayoutStyles';

export const AppointmentTrackerLayout = () => {

	const classes = {
		...layoutStyle().classes,
		...useLayoutStyles().classes
	}

	return (
		<Grid container item xs={12} lg={9} className={classes.titleSection}>
			<Grid item container>
				<img
					src="AT_LeftAlign.png"
					alt="Logo"
					className={classes.img}
				/>
			</Grid>
		</Grid>
	)
}