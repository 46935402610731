import {useSetRecoilState} from 'recoil';
import {editContactDrawer} from '../State/Drawer';
import React from 'react';
import {getContactById} from '../Services/ClientService';
import {editContactState} from '../State/Contact';
import {AutoCompleteOption} from './AutoCompleteOption';
import {IAutoCompleteOptionElementProps} from "../Types/input";

export const ContactAutoCompleteOption = ({option, inputProps}: IAutoCompleteOptionElementProps) => {

	const setContact = useSetRecoilState(editContactState)

	const setEditContactDrawer = useSetRecoilState(editContactDrawer)

	const handleEdit = async (value: any) => {
		const result = await getContactById(option.value)

		setContact(result.data);

		setEditContactDrawer(true);
	}
	return (
		<AutoCompleteOption option={option} onEdit={handleEdit} inputProps={inputProps}/>
	)
}