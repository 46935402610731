import {Button, DialogActions, DialogContent, DialogTitle, InputAdornment} from '@mui/material'
import {makeStyles} from 'tss-react/mui';
import {Form, Formik} from 'formik'
import {CompanyAutoCompleteOption} from '../FormFields/CompanyAutoCompleteOption'
import {DatePicker} from '../FormFields/DatePicker'
import {Input} from '../FormFields/Input'
import {ContactAutoCompleteOption} from '../FormFields/ContactAutoCompleteOption'
import {FormSelect} from '../FormFields/FormSelect'
import {
	AppointmentMethod,
	AppointmentMethodLabel,
	AppointmentSource,
	Duration
} from '../Types/appointment'
import {methodOptions} from '../dropdownOptions'
import {YesNoSwitch} from '../FormFields/YesNoSwitch';
import {appointmentValidationSchema} from '../FormikProps/Validation';
import {BDMAutoCompleteOption} from "../FormFields/BDMAutoComplete";
import {GenericAutoComplete} from "../FormFields/GenericAutoComplete";
import {CreateContactSlider} from "./CreateContactSlider";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import {AutocompleteRenderInputParams} from "@mui/material/Autocomplete";
import {CreateCustomerSlider} from "./CreateCustomerSlider";
import {useAppointmentForm} from "../Hooks/useAppointmentForm";
import {CreateConferenceSlider} from "./CreateConferenceSlider";
import {ConferenceAutoCompleteOption} from "../FormFields/ConferenceAutoCompleteOption";
import {useRecoilValue} from "recoil";
import {isAdminState} from "../State/Auth";
import {FollowUpReasonAutoCompleteOption} from "../FormFields/FollowUpReasonAutoCompleteOption";
import {CreateFollowUpReasonSlider} from "./CreateFollowUpReasonSlider";

const useStyles = makeStyles()({
	icon : {
		cursor: 'pointer'
	}
})

interface AppointmentFormProps {
	initialValues: any,
	onSubmit: any,
	label: string
}

export const AppointmentForm = ({initialValues, onSubmit, label}: AppointmentFormProps) => {
	const { classes } = useStyles();

	const {
		formRef,
		custOptions,
		handleCustomerDrawer,
		contOptions,
		handleContactDrawer,
		bdmsOptions,
		exceptionErrorMessages,
		handleClose,
		userEvents,
		linkToExistingEventState,
		sendToContactState,
		showEventsSelection,
		handleDateChange,
		handleBdmIdChange,
		handleConferenceDrawer,
		confOptions,
		handleCustomerChange,
		reasonOptions,
		handleFollowUpReasonDrawer,
		handleContactChange,
		pendingFollowUpCheck
  } = useAppointmentForm(initialValues);

	const isAdmin = useRecoilValue(isAdminState);

	return (
		<Formik
			innerRef={formRef}
			initialValues={initialValues}
			validationSchema={appointmentValidationSchema}
			onSubmit={onSubmit}
			validateOnMount
			enableReinitialize
		>
			{props => {
				return <Form>
					<DialogTitle
						id="form-dialog-title">
						{label} Appointment
					</DialogTitle>

					<DialogContent>
						<GenericAutoComplete
							name={'customerId'}
							formProps={props}
							label={'Customer'}
							placeholder={'Select Customer'}
							options={custOptions}
							validationProperty={'CustomerId'}
							RenderOptions={CompanyAutoCompleteOption}
							ExtraFormSlider={CreateCustomerSlider}
							customChangeHandler={handleCustomerChange}
							TextFieldInputProps={(params: AutocompleteRenderInputParams) => {
								return {
									...params.InputProps,
									startAdornment: <>
											<InputAdornment
												onClick={handleCustomerDrawer}
												position="start"
											>
												<AddIcon className={classes.icon}/>
											</InputAdornment>
											{params.InputProps.startAdornment}
										</>
								}
							}}
						/>

						<GenericAutoComplete
							name={'contactIds'}
							formProps={props}
							label={'Contact'}
							placeholder={'Select Contacts'}
							options={contOptions}
							RenderOptions={ContactAutoCompleteOption}
							ExtraFormSlider={CreateContactSlider}
							multiple={true}
							validationProperty={'ContactIds'}
							customChangeHandler={handleContactChange}
							TextFieldInputProps={(params: AutocompleteRenderInputParams) => {
								return {
									...params.InputProps,
									startAdornment: <>
											<InputAdornment
												onClick={handleContactDrawer}
												position="start"
											>
												<AddIcon className={classes.icon}/>
											</InputAdornment>
											{params.InputProps.startAdornment}
										</>
								}
							}}
						/>

						<GenericAutoComplete
							name={'userIds'}
							label={'BDM'}
							placeholder={'Select BDMs'}
							formProps={props}
							options={bdmsOptions}
							RenderOptions={BDMAutoCompleteOption}
							multiple={true}
							validationProperty={'UserIds'}
							customChangeHandler={handleBdmIdChange}
						/>

						<DatePicker
							name={'appointmentDateTime'}
							label={'Date And Time'}
							type={'datetime-local'}
							value={props.values.appointmentDateTime}
							onChange={(e: React.FormEvent<HTMLInputElement>)=>{
								props.handleChange(e);
								handleDateChange(e);
							}}
							onBlur={props.handleBlur}
						/>

						<YesNoSwitch
							name={'linkToExistingEvent'}
							formProps={props}
							label={"Link to existing event on your calendar?"}
							componentState={linkToExistingEventState}
						/>

						{showEventsSelection &&
							<FormSelect
								name={'googleEventId'}
								label={'Your events'}
								options={userEvents}
								validationProperty={'googleEventId'}
							/>
						}

						<FormSelect
							name={'method'}
							label={'Method'}
							options={methodOptions}
							validationProperty={'method'}
						/>

						{props.values.method !== null
							&& props.values.method !== AppointmentMethod.conference
							&& props.values.method !== AppointmentMethod.faceToFace
							&& <FormSelect
								name={'methodId'}
								label={AppointmentMethodLabel[props.values.method]}
								options={bdmsOptions}
								includeEmptyOption
								validationProperty={'MethodId'}
							/>

						}
						{props.values.method === AppointmentMethod.conference &&
						<GenericAutoComplete
							name={'conferenceId'}
							formProps={props}
							label={'Conference'}
							placeholder={'Select Conference'}
							options={confOptions}
							validationProperty={'conferenceId'}
							RenderOptions={ConferenceAutoCompleteOption}
							ExtraFormSlider={CreateConferenceSlider}
							TextFieldInputProps={(params: AutocompleteRenderInputParams) => {
								return {
									...params.InputProps,
									startAdornment: isAdmin && <>
										<InputAdornment
											onClick={handleConferenceDrawer}
											position="start"
										>
											<AddIcon className={classes.icon}/>
										</InputAdornment>
										{params.InputProps.startAdornment}
									</>
								}
							}}
						/>
						}

						{props.values.method === AppointmentMethod.faceToFace &&
							<Input
								name={'location'}
								type={'text'}
								label={'Location'}
								multiline={false}
								formProps={props}
								validationProperty={'Location'}
								value={initialValues.location}
							/>
						}

						<FormSelect
							name={'duration'}
							label={'Duration'}
							options={[
								{
									label: '15 Minutes', value: Duration.fifteen
								},
								{
									label: '30 Minutes', value: Duration.thirty
								},
								{
									label: '45 Minutes', value: Duration.fortyFive
								},
								{
									label: '1 hour', value: Duration.hour
								},
							]}
							validationProperty={'Duration'}
						/>

						<FormSelect
							name={'source'}
							label={'Source'}
							options={[
								{
									label: 'Linkedin',
									value: AppointmentSource.linkedin
								},
								{
									label: 'Zoom Info',
									value: AppointmentSource.zoomInfo
								},
								{
									label: 'Phone',
									value: AppointmentSource.phone
								},
								{
									label: 'Other',
									value: AppointmentSource.other
								}
							]}
						>
						</FormSelect>

						<Input
							name={'description'}
							type={'text'}
							label={'Description'}
							multiline={true}
							formProps={props}
							validationProperty={'description'}
							value={initialValues.description}
						/>

						{props.values.isFollowUp &&
							<GenericAutoComplete
								name={'followUpReasonId'}
								formProps={props}
								label={'Follow Up Reason'}
								placeholder={'Select Reason'}
								options={reasonOptions}
								validationProperty={'followUpReasonId'}
								RenderOptions={FollowUpReasonAutoCompleteOption}
								ExtraFormSlider={CreateFollowUpReasonSlider}
								TextFieldInputProps={(params: AutocompleteRenderInputParams) => {
									return {
										...params.InputProps,
										startAdornment: isAdmin && <>
											<InputAdornment
												onClick={handleFollowUpReasonDrawer}
												position="start"
											>
												<AddIcon className={classes.icon}/>
											</InputAdornment>
											{params.InputProps.startAdornment}
										</>
									}
								}}/>
						}


						<YesNoSwitch
							name={'sendToContact'}
							formProps={props}
							label={'Send invite to contacts?'}
							componentState={sendToContactState}
						/>

						<div className='validation-message'>
							{exceptionErrorMessages?.length !== 0 && exceptionErrorMessages}
						</div>
					</DialogContent>

					<DialogActions>
						<Button
							onClick={handleClose}
							color="primary"
						>
							Cancel
						</Button>
						<Button
							color="primary"
							type="submit"
							disabled={!props.isValid && pendingFollowUpCheck}
						>
							Submit
						</Button>
					</DialogActions>
				</Form>
			}}
		</Formik>
	)
}