import {Drawer} from '@mui/material';
import {followUpReasonInitialValues} from '../FormikProps/InitialValues';
import {useCreateFollowUpReasonSlider} from "../Hooks/useCreateFollowUpReasonSlider";
import {FollowUpReasonForm} from "./FollowUpReasonForm";

export const CreateFollowUpReasonSlider = () => {

    const {
        handleClose,
        followUpReasonDrawerIsOpen,
        onSubmit
    } = useCreateFollowUpReasonSlider();

    return (
        <>
            <Drawer
                onClose={handleClose}
                open={followUpReasonDrawerIsOpen}
                anchor="right"
                variant="temporary"
            >
                <FollowUpReasonForm
                    onSubmit={onSubmit}
                    initialValues={followUpReasonInitialValues}
                    label={'Create Follow Up Reason'}
                />
            </Drawer>
        </>
    )
}