import '../css/App.css'

export const Unauthorised = () => {
	return (
		<div className={'text'}>
			<h1>
				<strong>Access Denied</strong>
			</h1>
			<img src={'/access-denied.png'} alt={"Access denied"} />
		</div>
	)
}