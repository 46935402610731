import {useRecoilState} from "recoil";
import {conferenceDrawer} from "../State/Drawer";
import {useErrorMessages} from "./useErrorMessages";
import {conferencesState} from "../State/Conference";
import {IConferenceModel} from "../Types/Conference";
import {createConference} from "../Services/ConferenceService";
import {toast} from "react-toastify";

export const useCreateConferenceSlider = () => {

    const [conferenceDrawerIsOpen, setConferenceDrawer] = useRecoilState(conferenceDrawer);

    const {
        resetAllErrorMessages,
        handleErrorResponse
    } = useErrorMessages();

    const [conferences, setConferences] = useRecoilState(conferencesState);

    const onSubmit = async (values: IConferenceModel) => {
        try {

            const conference = await createConference(values);

            toast.success('Conference successfully created');

            setConferences([...conferences, conference.data]);

            setConferenceDrawer(false);

            resetAllErrorMessages();

        } catch (e) {
            handleErrorResponse(e);
            toast.error('Failed to create conference');
        }
    };

    const handleClose = () => {
        setConferenceDrawer(false);

        resetAllErrorMessages();
    }

    return {
        handleClose,
        conferenceDrawerIsOpen,
        onSubmit
    }
}