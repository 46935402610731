import {Button, Card, CardContent, Grid, Typography} from '@mui/material';
import {useGetAvailableAppointments} from '../Hooks/useGetAvailableAppointments';
import {AppointmentSlot, AppointmentSlots} from '../Types/User';
import {useLayoutStyles} from '../theme';
import React, {useState} from 'react';
import {useSetRecoilState} from 'recoil';
import {contactBookAppointmentDrawer} from '../State/Drawer';
import {formContactAppointmentState} from '../State/Contact';
import {useAppointmentSlotCardStyles} from './ui/Styles/appointmentSlotCardStyles';
import useIntegrationAppointment from "../Hooks/useIntegrationAppointment";

interface AvailableTimeSlotListProps {
	data: AppointmentSlots[]
}

interface AvailableTimeSlotListItemProps {
	item: AppointmentSlots
}

interface AppointmentSlotCardProps {
	item: AppointmentSlot
}

const AppointmentSlotCard = (props: AppointmentSlotCardProps) => {
	const [hovering, setHovering] = useState(false)
	const classes = {
		...useAppointmentSlotCardStyles(hovering).classes,
		...useLayoutStyles().classes
	}

	const {item: {start, durationMinutes, contact, bdmId}} = props

	const setContactBookAppointmentDrawer = useSetRecoilState(contactBookAppointmentDrawer);

	const setFormContactAppointmentDate = useSetRecoilState(formContactAppointmentState);

	const toggleHover = () => setHovering(curr => !curr)

	const handleAppointmentClick = () => {
		setFormContactAppointmentDate({start, contact, bdmId});

		setContactBookAppointmentDrawer(true)
	}

	const date = new Date(start);

	return (
		<Card
			onClick={handleAppointmentClick}
			className={classes.root}
			onMouseEnter={toggleHover}
			onMouseLeave={toggleHover}>
			<CardContent>
				<Typography variant="h6" classes={{
					root: classes.typographyPrimary
				}}
				>
					<div>
						{date.toLocaleTimeString()}
					</div>
				</Typography>
				<Typography
					classes={{
						root: classes.typographySecondary
					}}
					className={classes.pt}
				>
					Duration: {durationMinutes} minutes
				</Typography>
			</CardContent>

		</Card>
	)
}

const AvailableTimeSlotListItem = (props: AvailableTimeSlotListItemProps) => {
	const {classes} = useLayoutStyles()

	const {item: {key, slots}} = props

	return (
		<Grid
			item
			xs={12}
			container
		>
			<Grid
				item
				xs={12}
				className={classes.p}
			>
				<Typography
					variant="h4"
					color="primary"
				>
					{key}
				</Typography>
				<Typography
					variant="h5"
					color="secondary"
				>
					Select the timeslot that best suits you
				</Typography>
			</Grid>
			{
				(slots && slots.length > 0) && (
					<>
						{
							slots.map((slot: AppointmentSlot, index: number) => {
								return (
									<Grid
										key={`${key}-${index}`}
										item
										xs={12}
										sm={6}
										md={4}
										lg={3}
										className={classes.p}>
										<AppointmentSlotCard
											item={slot}
										/>
									</Grid>
								)
							})
						}
					</>
				)
			}
		</Grid>
	)
}

const AvailableTimeSlotList = (props: AvailableTimeSlotListProps) => {
	const {data} = props

	return (
		<>
			<Grid
				container
			>
				{
					data.map((item: AppointmentSlots, index: number) => {
						return (
							<AvailableTimeSlotListItem
								key={`slot-list-${index}`}
								item={item}
							/>
						)
					})
				}
			</Grid>
		</>
	)
}

const AvailableTimeSlots = () => {
	const {isLoading, data} = useGetAvailableAppointments()
	return (
		<Grid container>
			{
				(isLoading) && (
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="h3"
							color="primary"
						>
							Finding the next available appointments...
						</Typography>
					</Grid>
				)
			}
			{
				(!isLoading && data) && (
					<AvailableTimeSlotList
						data={data}
					/>
				)
			}
		</Grid>
	)
}

export {AvailableTimeSlots}
