import { atom, selector } from "recoil";


export const deleteDialog = atom({
    key: "DeleteDialog",
    default: false
})

export const deleteDialogState = selector({
    key: "DeleteDialogState",
    get: ({ get }) => {
        const state = get(deleteDialog);
        return state;

    }
})