import Drawer from '@mui/material/Drawer/Drawer'
import React from 'react'
import {editContactDrawer} from '../State/Drawer';
import {useRecoilState, useRecoilValue} from 'recoil';
import {contactEditedState, editContactState} from '../State/Contact';
import {ContactForm} from './ContactForm';
import {editContactById} from '../Services/ClientService';
import {IContactEditFormModel} from '../Types/Contact';
import {toast} from 'react-toastify';
import {useErrorMessages} from "../Hooks/useErrorMessages";

export const EditContactSlider = () => {

	const [editContactDrawerIsOpen, setEditContactDrawer] = useRecoilState(editContactDrawer);

	const [edit, setEdit] = useRecoilState(contactEditedState);

	const {
		resetAllErrorMessages,
		handleErrorResponse
	} = useErrorMessages();

	const contactValue = useRecoilValue(editContactState);

	const onSubmit = async (values: IContactEditFormModel) => {

		try {

			await editContactById(values);

			toast.success('Contact successfully edited');

			setEdit(edit + 1);

			setEditContactDrawer(false);

			resetAllErrorMessages();

		} catch (e) {
			handleErrorResponse(e);
			toast.error('Contact update failed');
		}
	};

	const handleClose = () => {
		setEditContactDrawer(false);

		resetAllErrorMessages();
	}

	return (
		<Drawer
			onClose={handleClose}
			open={editContactDrawerIsOpen}
			anchor="right"
			variant="temporary"
		>
			<ContactForm
				onSubmit={onSubmit}
				initialValues={contactValue}
				label={'Edit Contact'}
			/>
		</Drawer>
	)
}