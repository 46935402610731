import {useRecoilState, useResetRecoilState} from "recoil";
import {exceptionErrorMessageState, validationErrorMessagesState} from "../State/Error";

export const useErrorMessages = () => {

    const [validationErrorMessage, setValidationErrorMessage] = useRecoilState(validationErrorMessagesState);

    const resetValidationErrorMessages = useResetRecoilState(validationErrorMessagesState);

    const [exceptionErrorMessage, setExceptionErrorMessage] = useRecoilState(exceptionErrorMessageState);

    const resetExceptionErrorMessages = useResetRecoilState(exceptionErrorMessageState);

    const resetAllErrorMessages = () =>{
        resetValidationErrorMessages();
        resetExceptionErrorMessages();
    }

    const handleErrorResponse = (error: any) => {
        const response = error.response.data;

        if (response) {

            if (response.message) {
                setExceptionErrorMessage(response.message);
            } else {
                setExceptionErrorMessage(response);
            }

            if (response.detail) {
                setValidationErrorMessage(response.detail);
            }

        }
    }

    return {
        validationErrorMessage,
        setValidationErrorMessage,
        resetValidationErrorMessages,
        exceptionErrorMessage,
        setExceptionErrorMessage,
        resetExceptionErrorMessages,
        resetAllErrorMessages,
        handleErrorResponse
    }
}