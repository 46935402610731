import {useSetRecoilState} from "recoil";
import {editBdmState} from "../State/Bdm";
import {editBdmDrawer} from "../State/Drawer";
import {getBdmById} from "../Services/BdmService";

export const useEditBdmSlider = () => {
    const setBdm = useSetRecoilState(editBdmState);

    const setEditBdmDrawer = useSetRecoilState(editBdmDrawer);

    const handleBdmSliderOpen = async (bdmId: number) => {
        const result = await getBdmById(bdmId);

        setBdm(result.data);

        setEditBdmDrawer(true);
    }

    return handleBdmSliderOpen;
}