import {Drawer} from '@mui/material';
import {useRecoilState, useResetRecoilState, useSetRecoilState} from 'recoil';
import {customerInitialValues} from '../FormikProps/InitialValues';
import {customerDrawer} from '../State/Drawer';
import {ICustomerFormModel} from '../Types/Customer';
import {createCustomer} from '../Services/CustomerService';
import {customersState} from '../State/Customer';
import {toast} from 'react-toastify';
import {IAutoCompleteProps} from '../Types/appointment';
import {CustomerForm} from './CustomerForm';
import {exceptionErrorMessageState, validationErrorMessagesState} from '../State/Error';
import {useErrorMessages} from "../Hooks/useErrorMessages";

export const CreateCustomerSlider = ({fieldProps}: IAutoCompleteProps) => {

	const [customerDrawerIsOpen, setCustomerDrawer] = useRecoilState(customerDrawer);

	const {
		resetAllErrorMessages,
		handleErrorResponse
	} = useErrorMessages();

	const [customers, setCustomer] = useRecoilState(customersState);

	const onSubmit = async (values: ICustomerFormModel) => {

		try {

			const customer = await createCustomer(values);

			toast.success('Company successfully created');

			setCustomer([...customers, customer.data]);

			fieldProps.form.setFieldValue('customerId', customer.data.customerId);

			setCustomerDrawer(false);

			resetAllErrorMessages();

		} catch (e) {
			handleErrorResponse(e);
			toast.error('Failed to create customer');
		}
	};

	const handleClose = () => {
		setCustomerDrawer(false);

		resetAllErrorMessages();
	}

	return (
		<>
			<Drawer
				onClose={handleClose}
				open={customerDrawerIsOpen}
				anchor="right"
				variant="temporary"
			>
				<CustomerForm
					onSubmit={onSubmit}
					initialValues={customerInitialValues}
					label={'Create Company'}
				/>
			</Drawer>
		</>
	)
}