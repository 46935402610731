import axios from "axios";
import {rootUrl} from "./AppointmentService";
import {IFollowUpReasonModel} from "../Types/FollowUpReason";

export const getFollowUpReasons = async () => axios.get<IFollowUpReasonModel[]>(`${rootUrl}/appointment/follow-up-reasons`);

export const getFollowUpReasonById = async (reasonId: number) => await axios.get(`${rootUrl}/appointment/follow-up-reasons/${reasonId}`);

export const createFollowUpReason = async (followUpReason: IFollowUpReasonModel) => await axios.post(`${rootUrl}/appointment/follow-up-reasons`, followUpReason)

export const editFollowUpReason = async (followUpReason: IFollowUpReasonModel) => await axios.put(`${rootUrl}/appointment/follow-up-reasons/${followUpReason.id}`, followUpReason)
