import { Button } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	root: {
		borderRadius: 25,
		minWidth: 250,
		letterSpacing: 2,
		lineHeight: 1.7,
		paddingTop: '0.9rem',
		paddingBottom: '0.9rem',
		paddingLeft: '1.25rem',
		paddingRight: '1.25rem',
		fontSize: '0.56rem',
		width: 'auto'
	},
	contained: {
		boxShadow: 'none',
		backgroundColor: '#010102',
		color: '#fff',
		'&&:hover': {
			backgroundColor: '#8b9da5',
			boxShadow: 'none'
		}
	}
}));

export default function BookAppointmentButton({productId}: any) {
	const { classes } = useStyles();

	const url = productId ? `/create/${productId}` : '/create';

	return (
		<Button variant="contained" href={url} classes={{
			root: classes.root,
			contained: classes.contained
		}}>
			Book your appointment
		</Button>
	)
}